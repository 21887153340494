import BABYLON from "./babylon-modules";
// import * as BABYLON from "@babylonjs/core/Legacy/legacy";

export default class Obj {

    constructor(scene, groundMesh, typeObject) {
        this.scene = scene;
        this.groundMesh = groundMesh;
        this.typeObject = typeObject;

        // mandatory properties for all obstacle
        this.mesh = null;
        this.height = null;

        this.groundSize = 50;
        if (typeObject.includes("rock3")) {
            this.groundPosition = new BABYLON.Vector3(0, this.groundMesh.absolutePosition.y - 0.25, 0);
        } else {
            this.groundPosition = new BABYLON.Vector3(0, this.groundMesh.absolutePosition.y - 0.1, 0);
        }
    }

    definePosition(line) {
        let linesPosZ = [];

        if (this.typeObject === "decor") {
            linesPosZ = [-0.38, -0.13, 0.13, 0.38];
        } else {
            linesPosZ = [-0.45, -0.25, 0, 0.25, 0.45];
        }

        const posY = 0.8;

        const offset = Math.random() * (0.08 + 0.08) - 0.08;
        const posZ = linesPosZ[line] + offset + this.groundMesh.parent.rotation.x * 0.6;
        // const posZ = linesPosZ[line] + offset;

        const posX = -1.8 + this.groundMesh.parent.rotation.x;
        const directionPoint = new BABYLON.Vector3(posX, posY, posZ);
        directionPoint.normalize();

        this.mesh.position = this.groundPosition.add(directionPoint.scale(this.groundSize + this.height * 0.5));

        const yaxis = this.mesh.position.subtract(this.groundPosition).normalize();
        const xaxis = BABYLON.Vector3.Cross(new BABYLON.Vector3(0, 1, 0), yaxis); // 1: BABYLON.Axis.Y
        const zaxis = BABYLON.Vector3.Cross(xaxis, yaxis);

        if (this.mesh.rotationQuaternion) {
            const rotEuler = this.mesh.rotationQuaternion.toEulerAngles();
            this.mesh.rotationQuaternion = null;
            if (!rotEuler) {
                // debugger;
            }
            this.mesh.rotation = rotEuler;
        }

        this.mesh.computeWorldMatrix(true);
        let rot = BABYLON.Vector3.RotationFromAxis(xaxis, yaxis, zaxis);
        this.mesh.rotation.copyFromFloats(rot.x, rot.y, rot.z);

        const randomRotation = Math.random() * (Math.PI + Math.PI) - Math.PI;
        this.mesh.rotate(new BABYLON.Vector3(0, 1, 0), randomRotation, BABYLON.Space.LOCAL);


        this.mesh.setParent(this.groundMesh);
    }

    isVisible() {
        if (!this.mesh) {
            return true;
        }

        if (this.mesh.absolutePosition.y < (this.groundPosition.y + this.groundSize) && this.mesh.absolutePosition.x > (this.groundPosition.x + this.groundSize * 0.1)) {
            return false;
        }
        return true;
    }

}
