export default {
    name: "proc-scene",
    requires: ["obsidian-babylon-engine", "obsidian-material-manager"],
    exposed: ["altitude", "distance"],
    load() {
        const ProcScene = require("./src/proc-scene").default;
        const ProcSceneVue = require("./src/proc-scene.vue").default;

        const controller = new ProcScene();
        this.bind(ProcSceneVue, controller, this.exposed);

        return {
            controller: controller,
            view: ProcSceneVue,
        }
    },
    unload() {},

    bind(vue, controller, exposed) {
        vue.methods = vue.methods || {};
        const data = vue.data ? vue.data() : {};

        for (let i = 0; i < exposed.length; i++) {
            if (controller[exposed[i]] instanceof Function) {
                vue.methods[exposed[i]] = (...args) => controller[exposed[i]](...args);
            } else {
                data[exposed[i]] = controller[exposed[i]];
            }
        }

        vue.data = () => data;
    },
};
