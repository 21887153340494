export default {
    name: "asset-manager",
    requires: ["obsidian-babylon-engine", "proc-scene"],
    load() {
        const AssetManager = require("./src/asset-manager").default;
        const controller = new AssetManager();

        return new Promise((resolve, reject) => {
            this.app.modules.obsidianBabylonEngine.waitForLoading()
                .then(controller.preloadAllModels).then(() => {
                    this.app.events.emit("assetsLoaded");
                    console.log("Assets loaded");
                    resolve(() => controller);
                });
        });
    },
    unload() {},
};
