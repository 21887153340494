<!-- Main Vue for the obsidian app -->

<template>
    <!-- HTML template -->
    <div id="app">
        <div id="loading-screen" />


        <div
            id="ui"
        >
            <div id="logo">
                <img
                    alt=""
                    src="/assets/images/plane.svg"
                >
            </div>
            <sound />
            <div
                id="bywanadev"
            >
                <a href="https://wanadev.fr" target="_blank" rel="noreferrer">By wanadev</a>
            </div>
            <div id="share">
                <div id="share-text">
                    Share
                </div>
                <div id="socials">
                    <!-- Facebook -->
                    <div
                        id="facebook"
                        class="circle-button ui facebook button"
                        @click="facebookShare"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="5"
                            height="11"
                            viewBox="0 0 5 11"
                        >
                            <path
                                fill="#FFF"
                                fill-rule="evenodd"
                                d="M3.325 11H1.108V5.5H0V3.603h1.108V2.466C1.108.92 1.733 0 3.51 0h1.48v1.896h-.925c-.692 0-.737.265-.737.76l-.003.948H5l-.196 1.895h-1.48V11z"
                            />
                        </svg>
                    </div>
                    <!-- Twitter -->
                    <div
                        id="twitter"
                        class="circle-button"
                        @click="twitterShare"
                    >
                        <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="10"
                            height="9"
                            viewBox="0 0 10 9"
                        >
                            <path
                                fill="#FFF"
                                fill-rule="evenodd"
                                d="M8.822 1.423c.424-.28.749-.726.902-1.257-.397.261-.836.45-1.304.552A1.958 1.958 0 0 0 6.923 0c-1.133 0-2.05 1.017-2.05 2.272 0 .178.016.351.052.517C3.22 2.694 1.709 1.791.696.415A2.455 2.455 0 0 0 .42 1.558c0 .788.362 1.483.912 1.892a1.91 1.91 0 0 1-.93-.284v.027c0 1.102.707 2.02 1.647 2.228a1.825 1.825 0 0 1-.927.04c.26.902 1.018 1.56 1.917 1.577A3.87 3.87 0 0 1 0 7.98 5.41 5.41 0 0 0 3.145 9c3.774 0 5.837-3.462 5.837-6.464 0-.1-.002-.197-.006-.294.401-.32.75-.721 1.024-1.177-.368.181-.764.303-1.178.358z"
                            />
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <div
            v-if="currentStep === 0 && !start"
            id="intro-container"
        >
            <intro />
        </div>
        <div
            id="main-game-container"
            :style="{display: currentStep === 0 ? 'block' : 'none'}"
        >
            <main-ui />
            <main-canvas />
        </div>
        <div
            id="result-container"
            :style="{display: currentStep === 1 ? 'block' : 'none'}"
        >
            <result />
        </div>
    </div>
</template>

<script>
// Js Part
import lottie from "lottie-web";
import self from "../../index";
// Vue components
// import MainMenu from "./main-menu.vue";
import Intro from "./intro.vue";
import MainCanvas from "./main-canvas.vue";
import MainUi from "./main-ui.vue";
import Result from "./result.vue";
import Sound from "./sound.vue";
// Minimal export
export default {
    name: "app",
    components: {
        // "main-menu": MainMenu,
        intro: Intro,
        "main-canvas": MainCanvas,
        "main-ui": MainUi,
        result: Result,
        sound: Sound,
    },
    data() {
        return {
            currentStep: 0,
            isReady: false,
            start: false,
        };
    },
    created() {
        self.app.log.info("Hey, it's me, the main vue for your Obsidian app", this.meshManager);
        self.app.events.on("@greeting-card.explode", () => {
            setTimeout(() => {
                this.currentStep = 1;
            }, 3000);
        });
        self.app.events.on("@proc-scene.loader-hidden", () => {
            const ui = document.getElementById("ui");
            ui.style.opacity = "1";
        });
        self.app.events.on("@vuejs.updateCurrentStep", (currentStep) => {
            this.currentStep = currentStep;
            if (currentStep === 0) {
                self.app.events.emit("restart");
            }
        });
        self.app.events.on("@vuejs.startButtonPressed", () => {
            this.start = true;
            const intro = document.getElementById("intro-container");
            const game = document.getElementById("main-game-container");
            intro.style.display = "none";
            game.style.display = "inline";
            game.style.opacity = "1";
        });

        self.app.events.on("@obsidian.ready", () => {
            this.isReady = true;
        });
    },
    mounted() {
        const loadinScreenEl = document.getElementById("loading-screen");
        if (loadinScreenEl) {
            lottie.loadAnimation({
                container: loadinScreenEl, // the dom element that will contain the animation
                renderer: "svg",
                loop: true,
                autoplay: true,
                path: "assets/images/loader.json", // the path to the animation json
            });
        }
    },
    methods: {
        facebookShare: function (e) {
            const url = e.target.baseURI;
            window.open(`https://www.facebook.com/share.php?u=${url}`, "facebookwindow",
                "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0");
        },

        twitterShare: function (e) {
            const href = e.target.baseURI;
            const message = "Regardez ce super jeu !";
            e.preventDefault();
            window.open(`http://twitter.com/intent/tweet?url=${href}&text=${message}`, "twitterwindow",
                "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0");
        },
    },
};

</script>

<!-- the style can be in an other file like here, or directely in the .vue file -->
<style src="./app.less"></style>
<style lang="less">
    @left-padding: 16px;
    @top-padding: 16px;
    @bottom-padding: 12px;
    @right-padding: 16px;

    @font-face {
        font-family: "Montserrat-bold";
        src: url("/assets/fonts/Montserrat/Montserrat-Bold.ttf");
    }

    @font-face {
        font-family: "Montserrat-medium";
        src: url("/assets/fonts/Montserrat/Montserrat-Medium.ttf");
    }

    #ui {
        opacity: 0;
        transition: opacity 1s ease 1s;
    }

    #loading-screen {
        position: absolute;
        color: white;
        z-index: 100;
        top: 0px;
        left: 0px;
        width: 100%;
        height: 100%;
        background: #34005b;
        opacity: 1;
        transition: all 0.5s ease-out 1s;

        svg{
            width: 10% !important;
        }
        &.hidden {
            opacity: 0;
            pointer-events: none;
        }
    }

    #socials {
        display: inline-block;

        #facebook, #twitter {
            display: inline-flex;
            margin: auto;
            margin: 5px;
        }
    }

    #share-text {
        display: inline-block;
    }

    #share-text:after {
        content: "|";
        margin: 10px;
    }

    #logo {
        position: absolute;
        text-align: left;
        top: @top-padding;
        left: @left-padding;
        z-index: 21;
    }

    #sound-container {
        z-index: 21;
        position: relative;
        text-align: right;
        color: white;
        font-family: "Montserrat-medium", Arial, sans-serif;
        font-size: 12px;
    }

    #bywanadev {
        position: absolute;
        left: @left-padding;
        bottom: @bottom-padding;
        font-family: "Montserrat-bold", Arial, sans-serif;
        font-size: 14px;
        color: white;
        margin: 6px;
        z-index: 21;
        a:link, a:visited {
            color: inherit;
            text-decoration: none;
        }
    }

    #share {
        z-index: 21;
        position: absolute;
        right: @right-padding;
        bottom: calc(@bottom-padding - 5px);
        color: white;
        font-family: "Montserrat-medium", Arial, sans-serif;
        font-size: 12px;
    }

    #intro-container {
        z-index: 0;
        position: absolute;
        top: 0;
        height: 100vh;
        width: 100vw;
    }

@media screen and (max-width: 700px) and (orientation: portrait) {
    #loading-screen svg{
            width: 30% !important;
        }
}

</style>
