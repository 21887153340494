import MODELS_LIST from "./models-list";
import self from "..";

export default class AssetManager {
    preloadAllModels() {
        const allPromises = [];
        for (let i = 0; i < MODELS_LIST.length; i++) {
            allPromises.push(self.app.modules.procScene.controller.addToPreloadList(MODELS_LIST[i]));
        }
        self.app.modules.procScene.controller.loadMeshes();

        return Promise.all(allPromises);
    }
}
