<template>
    <div id="result">
        <div class="result-head-title">
            Félicitations
        </div>
        <img class="result-backgroundImg" alt="" src="/assets/images/backgroundImg.jpg"><br>
        <div class="result-distance">
            <span class="number">{{ computedDistance }}</span><br>
            <span class="unit">{{ computedUnit }}</span>
        </div>
        <div class="result-text">
            {{ resultText }} <br>
            Se lancer, s'élever, traverser les épreuves, dépasser ses limites.
            Parce qu'une année est aussi faite d'initiatives et de projets personnels et professionnels,
            l'équipe Wanadev souhaite que les vôtres aillent le plus loin possible.
            Excellente année 2020, à bientôt !
        </div>
        <div class="share-buttons-container">
            <button v-bind:class="{ 'close-button': shareScoreDialogOpened }" class="btn-empty" id="share-score" @click="toggleShareScoreDialog">
                <transition name="fade" mode="out-in">
                    <span v-bind:key="shareScoreDialogOpened">
                        {{ !shareScoreDialogOpened ? "partager votre score" : "X" }}
                    </span>
                </transition>
            </button>
            <transition name="insert-after" mode="out-in">
                <button class="btn-full" v-if="shareScoreDialogOpened" @click="shareScoreFacebook">
                    <svg xmlns="http://www.w3.org/2000/svg" width="5" height="11" viewBox="0 0 5 11">
                        <path fill="#34005b" fill-rule="evenodd" d="M3.325 11H1.108V5.5H0V3.603h1.108V2.466C1.108.92 1.733 0 3.51 0h1.48v1.896h-.925c-.692 0-.737.265-.737.76l-.003.948H5l-.196 1.895h-1.48V11z" />
                    </svg>
                </button>
            </transition>
            <transition name="insert-after" mode="out-in">
                <button class="btn-full" v-if="shareScoreDialogOpened" @click="shareScoreTwitter">
                    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="9" viewBox="0 0 10 9">
                        <path fill="#34005b" fill-rule="evenodd" d="M8.822 1.423c.424-.28.749-.726.902-1.257-.397.261-.836.45-1.304.552A1.958 1.958 0 0 0 6.923 0c-1.133 0-2.05 1.017-2.05 2.272 0 .178.016.351.052.517C3.22 2.694 1.709 1.791.696.415A2.455 2.455 0 0 0 .42 1.558c0 .788.362 1.483.912 1.892a1.91 1.91 0 0 1-.93-.284v.027c0 1.102.707 2.02 1.647 2.228a1.825 1.825 0 0 1-.927.04c.26.902 1.018 1.56 1.917 1.577A3.87 3.87 0 0 1 0 7.98 5.41 5.41 0 0 0 3.145 9c3.774 0 5.837-3.462 5.837-6.464 0-.1-.002-.197-.006-.294.401-.32.75-.721 1.024-1.177-.368.181-.764.303-1.178.358z" />
                    </svg>
                </button>
            </transition>
        </div>
        <button class="btn-full restart-button" @click="restart">
            réessayer
        </button>
    </div>
</template>
<script>
import self from "../../index";

export default {
    name: "result",
    data() {
        return {
            distance: 0,
            shareScoreDialogOpened: false,
        };
    },
    created() {
        self.app.events.on("@greeting-card.stopGame", () => {
            const result = document.getElementById("result");
            result.style.opacity = "1";
        });
    },
    computed: {
        computedDistance() {
            return parseInt(self.app.modules.procScene.controller.distance.value.toPrecision(10), 10);
        },
        computedUnit() {
            return (this.computedDistance > 1) ? "mètres" : "mètre";
        },
        resultText() {
            if (this.computedDistance < 100) {
                return "Oups ! Pourtant votre projet était bien engagé. Recommencez avec des bases solides !";
            }
            if (this.computedDistance < 500) {
                return "Beau décollage ! Votre projet gagnera à repartir de zéro pour gagner du terrain.";
            }
            if (this.computedDistance < 1000) {
                return "Vous avez bien avancé. Prenez de la hauteur et dépassez vos limites !";
            }
            if (this.computedDistance < 2020) {
                return "Votre projet a presque atteint son objectif. Recommencez pour relever le défi !";
            }

            return "Bravo ! Bienvenue à votre projet dans l’année 2020 !";
        },
    },
    mounted() {
        if (self.app.modules && self.app.modules.procScene) {
            this.distance = self.app.modules.procScene.controller.distance.value.toPrecision(3);
        }
    },
    methods: {
        restart() {
            self.app.events.emit("updateCurrentStep", 0);
        },
        toggleShareScoreDialog() {
            this.shareScoreDialogOpened = !this.shareScoreDialogOpened;
        },
        shareScoreFacebook(e) {
            const url = e.target.baseURI;
            window.open(`https://www.facebook.com/share.php?u=${url}`, "facebookwindow",
                "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0");
        },
        shareScoreTwitter(e) {
            const href = e.target.baseURI;
            const message = `J'ai parcouru ${this.computedDistance} mètres !`;
            e.preventDefault();
            window.open(`http://twitter.com/intent/tweet?url=${href}&text=${message}`, "twitterwindow",
                "height=450, width=550, toolbar=0, location=0, menubar=0, directories=0, scrollbars=0");
        },
    },
};

</script>
<!-- Basic style -->
<style lang="less">
@import "../../../../style/index.less";

.share-buttons-container {
    display: inline-flex;
    width: 220px;

    button {
        margin-right: 10px;
        white-space: nowrap;
        width: 38px;
        flex: 0 0 auto;
        border-radius: 50%;

        &.btn-full {
            margin-top: 0px;
            padding: 0px;
        }
    }
}

.insert-after-enter-active {
    transition: all 0.15s 0.15s;
}
.insert-after-leave-active {
    transition: all 0.15s;
}
.insert-after-enter,
.insert-after-leave-to {
    opacity: 0;
    width: 0px;
    transform: translateX(-60px);
}

.insert-after-leave,
.insert-after-enter-to {
    opacity: 1;
    width: 10px;
    font-size: 20px;
}

.fade-enter-active,
.fade-leave-active {
    transition: all 0.15s;
}

.fade-enter,
.fade-leave-to {
    opacity: 0;
    transform: rotate(90deg);
}

.fade-leave {
    opacity: 1;
    font-size: 20px;
    transform: rotate(0deg);
}

#share-score {
    transition: all 0.3s;
    width: 210px;
    padding: 10px 45px 10px 45px;
    border-radius: 19px;

    &.close-button {
        width: 38px;
        height: 38px;
        padding: 0;
    }
}

#result {
    color: white;
    text-align: center;
    opacity: 0;
    transition: opacity 1s linear;
    -webkit-transition: opacity 1s linear;
    position: absolute;
    height: 100%;
    width: 100%;
    top: 0;
    background: #34005b;
}

.result-head-title {
    font-family: "WildSpirit", Arial, sans-serif;
    font-size: 8em;
    z-index: 20;
    position: relative;
    text-shadow: 5px 5px 5px darkslategrey;
}

.result-backgroundImg {
    max-height: 50vh;
    min-width: 30%;
    max-width: 50%;
    margin-top: -11vh;
}

.result-distance {
    font-family: "Montserrat-bold", Arial, sans-serif;
    color: white;
    background-color: #34005b;
    width: 221px;
    height: 150px;
    border-radius: 6px;
    font-weight: bold;
    margin: auto;
    position: relative;
    z-index: 1;
    margin-top: -37vh;
    padding-top: 30px;

    .number {
        font-size: 4em;
    }

    .unit {
        font-family: "Canela-bold", Arial, sans-serif;
        font-size: 2em;
    }
}

.result-text {
    font-family: "Canela-Medium";
    font-size: 1em;
    line-height: 2;
    width: 524px;
    padding: 1em 2em;
    margin: 1rem auto;
    background-color: #34005b;
    position: relative;
    margin-top: 8vh;
    letter-spacing: 0.4px;
    z-index: 2;
}

@media screen and (max-width: 900px) {
    .result-head-title {
        font-size: 5em;
    }

    .result-distance {
        width: 140px;
        height: 140px;
        margin-top: -126px;
        padding-top: 20px;

        .number {
            font-size: 2.5em;
        }

        .unit {
            font-size: 0.9em;
        }
    }

    .result-backgroundImg {
        margin-top: -19vh;
        width: 40vw;
    }

    .result-text {
        font-size: 0.75em;
        line-height: 1.6;
        margin-top: -20vh;
        width: calc(100% - 4em);
        margin-top: -70px;
    }

    .btn-full {
        margin-top: 0;
    }
    .share-buttons-container, .restart-button{
        position: relative;
        top: -2vh;
    }
}

@media screen and (max-width: 700px) and (orientation: portrait) {
    .result-head-title {
        font-size: 6em;
    }

    .result-distance {
        margin-top: -20vh;
        height: 80px;
    }

    .result-text {
        margin-top: 0;
    }

    .result-backgroundImg {
        width: auto;
        margin-top: 0;
        max-width: 85%;
    }
}

</style>
