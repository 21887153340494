export default {
    name: "greeting-card",
    requires: ["obsidian-babylon-engine", "obsidian-material-manager", "proc-scene"],
    load() {
        const GreetingCard = require("./src/greeting-card").default;
        const GreetingCardVue = require("./src/greeting-card.vue").default;
        return {
            controller: new GreetingCard(),
            view: GreetingCardVue,
        };
    },
    unload() {},
};
