import BABYLON from "./babylon-modules";
import Obj from "./object";
import self from "../index";
// import Cylinder from "./cylinder";

export default class Obstacle extends Obj {

    constructor(obstacleType, scene, groundMesh, line) {
        super(scene, groundMesh, obstacleType);
        this.obstacleType = obstacleType;
        this.line = line;

        this.collisionChildren = [];
    }

    setup(mesh) {

        // const maxWidth = [2, 4];
        // const maxHeight = [3, 7];
        // this.height = Math.random() * (maxHeight[1] - maxHeight[0]) + maxHeight[0];

        // this.mesh = new BABYLON.MeshBuilder.CreateBox(
        //     this.obstacleType, {
        //         width: Math.random() * (maxWidth[1] - maxWidth[0]) + maxWidth[0],
        //         height: this.height,
        //     },
        //     this.scene,
        // );

        // const buildingMaterial = new BABYLON.StandardMaterial("buildingMaterial", this.scene);
        // buildingMaterial.diffuseColor = new BABYLON.Vector3(0.3, 0.3, 0.4);
        // this.mesh.material = buildingMaterial;
        const meshes = mesh.getDescendants();
        meshes.push(mesh);

        meshes.forEach((submesh) => {
            if (submesh.material) {
                submesh.material.transparencyMode = 1;
                submesh.material.needAlphaBlending(true);
            }
            // if (!submesh.id.includes("container") && !submesh.id.includes("root")) {
            //     console.log(submesh.material);
            //     submesh.material.transparencyMode = BABYLON.PBRMaterial.PBRMATERIAL_ALPHABLEND;
            //     submesh.material.forceAlphaTest = true;
            //     submesh.material.needdepthprepass = true;
            //     submesh.material.needAlphaBlending(true);
            // }
            // console.log(submesh.id);
            // console.log(submesh.material);
            // submesh.material.needAlphaBlending(true);
            if (submesh.id === ".__root__") {
                this.mesh = submesh;
                this.mesh.parent = null;
                this.mesh.scaling = new BABYLON.Vector3(0.01, 0.01, 0.01);
            } else if (submesh.id.includes("collision")) {
                this.collisionChildren.push(submesh);
                submesh.isVisible = false;
            } else if (submesh.id.includes("container")) {
                // if (submesh.material) {
                //     submesh.material = null;
                // }
                // submesh.material.needdepthprepass = false;
                // submesh.material.needAlphaBlending(false);
                // submesh.material.alpha = 0.0;
                this.collisionContainer = submesh;
                submesh.isVisible = false;
            }
        });

        super.definePosition(this.line);

        self.app.events.emit("loadedMesh", this);
    }

}
