import self from "../index";

export default class Controller {

    constructor() {
        this.procScene = self.app.modules.procScene.controller;
        this.greetingCard = self.app.modules.greetingCard.controller;

        this.uiData = {
            timeLoseAlt: 2,
            speedRotation: 3,
            speedRotationPlane: 3,
            gainAltDraft: 2,
            frequencyObstacles: 0.7, // sec
            nbObstaclesMin: 1,
            nbObstaclesMax: 3,
            frequencyDrafts: 0.3,
            errorMessage: "",
        };

        self.app.events.on("@equilibrage.updateField", () => {
            this.uiData.errorMessage = "";
            if (this.nbObstaclesMin > this.nbObstaclesMax) {
                this.uiData.errorMessage += "\nNombre d'obstacles min supérieur à maxi";
            }
            if (this.uiData.nbObstaclesMin < 0 || this.uiData.nbObstaclesMax > 5) {
                this.uiData.errorMessage += "\nLe nombre d'obstacles par ligne va de 0 à 5";
            }
            if (this.uiData.frequencyDrafts < 0 || this.uiData.frequencyDrafts > 1) {
                this.uiData.errorMessage += "\nProbabilité de 0 à 1";
            }
            if (!this.uiData.errorMessage) {
                self.app.events.emit("updateProperty", this.uiData);
            }
        });
    }

}
