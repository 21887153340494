<!-- Main menu (on the left side of the canvas)-->
<!-- Container for other menu elements  -->
<template>
    <div id="ui-view">
        <gameUi />
        <!-- <equilibrage /> -->
    </div>
</template>

<script>
import self from "../../index";

export default {
    name: "mainUi",
    components: {
        gameUi: self.app.modules.procScene.view,
        // equilibrage: self.app.modules.equilibrage.view,
    },
    mounted() {
        self.app.events.on("@vuejs.startButtonPressed", () => {
            const ui = document.getElementById("ui-view");
            ui.style.zIndex = "20";
            ui.style.opacity = "1";
        });
        self.app.events.on("@vuejs.restart", () => {
            const ui = document.getElementById("ui-view");
            ui.style.zIndex = "20";
        });
    },
    methods: {
    },
};
</script>

<style>
    #ui-view {
        width : 100%;
        height : 100%;
        position: absolute;
        top: 0;
        z-index: -10;
        pointer-events: none;
        opacity: 0;
        transition: opacity .4s ease;
    }

    #ui-view > * {
        pointer-events: all;
    }
</style>
