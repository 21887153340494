import { ActionManager } from "@babylonjs/core/Actions/actionManager";
import { ExecuteCodeAction } from "@babylonjs/core/Actions/directActions";
import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { ParticleSystem, SphereParticleEmitter, Texture } from "@babylonjs/core";
import { Quaternion, Vector3, Space, Color3 } from "@babylonjs/core/Maths/math";
import { ShadowGenerator } from "@babylonjs/core/Lights/Shadows/shadowGenerator";
import { Sound } from "@babylonjs/core/Audio/sound";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";

const BABYLON = {
    ActionManager,
    Color3,
    ExecuteCodeAction,
    MeshBuilder,
    ParticleSystem,
    Quaternion,
    ShadowGenerator,
    Sound,
    Space,
    SphereParticleEmitter,
    StandardMaterial,
    Texture,
    Vector3,
};
export default BABYLON;
