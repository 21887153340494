import self from "../index";
import BABYLON from "./babylon-modules";

export default class AudioEngineController {

    constructor() {
        this.engine = self.app.modules.obsidianBabylonEngine;
        this.isSoundOn = true;

        this.engine.waitForLoading().then(() => {
            this.scene = this.engine.scene;
            this.mainLoop = this.engine.mainLoop;

            this.setup();
        });
    }

    setup() {
        BABYLON.AudioEngine.useCustomUnlockedButton = true;
        BABYLON.AudioEngine.unlocked = true;
        this.link = "../../../assets/sounds/";

        self.app.events.emit("audioEngineReady");

        this.ambiantMusic = new BABYLON.Sound("ambiantMusic", this.link.concat("AmbiantMusic.wav"), this.scene, null, { loop: true, autoplay: true, volume: 0.4 });
        this.startButton = new BABYLON.Sound("startButton", this.link.concat("StartButton.wav"), this.scene);
        this.loadAltitude = new BABYLON.Sound("loadAltitude", this.link.concat("LoadAltitudeStart.wav"), this.scene);
        this.planeTurning = new BABYLON.Sound("planeTurning", this.link.concat("Wind.mp3"), this.scene, null, { loop: true, volume: 2 });
        this.planeOnTree = new BABYLON.Sound("planeOnTree", this.link.concat("PlaneOnTree.wav"), this.scene);
        this.planeOnStone = new BABYLON.Sound("planeOnStone", this.link.concat("PlaneOnStone.wav"), this.scene);
        this.planeOnGround = new BABYLON.Sound("planeOnGround", this.link.concat("PlaneOnGround.wav"), this.scene);
        this.planeOnDraft = new BABYLON.Sound("planeOnDraft", this.link.concat("PlaneOnDraft.wav"), this.scene);
        this.applauseStopGame = new BABYLON.Sound("applauseStopGame", this.link.concat("ApplauseStopGame.wav"), this.scene, null, { volume: 0.7 });
        this.displayDistance = new BABYLON.Sound("displayDistance", this.link.concat("DisplayDistance.wav"), this.scene); // TODO: change sound

        this.allSounds = [];
        this.allSounds.push(this.startButton);
        this.allSounds.push(this.loadAltitude);
        this.allSounds.push(this.planeTurning);
        this.allSounds.push(this.planeOnTree);
        this.allSounds.push(this.planeOnStone);
        this.allSounds.push(this.planeOnGround);
        this.allSounds.push(this.planeOnDraft);
        this.allSounds.push(this.applauseStopGame);
        this.allSounds.push(this.displayDistance);

        self.app.events.on("@vuejs.setSound", (isSoundOn) => {
            this.isSoundOn = isSoundOn;
            if (isSoundOn) {
                this.ambiantMusic.setVolume(0.4);
                this.allSounds.forEach((sound) => {
                    sound.setVolume(1);
                });
            } else if (!isSoundOn) {
                this.ambiantMusic.setVolume(0);
                this.allSounds.forEach((sound) => {
                    sound.setVolume(0);
                });
            }
        });

        self.app.events.on("@vuejs.startButtonPressed", () => {
            this.startButton.play();
        });

        self.app.events.on("@proc-scene.loadAltitude", () => {
            this.loadAltitude.play();
        });

        self.app.events.on("@proc-scene.stopLoadAltitude", () => {
            this.planeTurning.play();
        });

        self.app.events.on("@greeting-card.stopLoadAltitude", () => {
            this.planeTurning.play();
        });

        self.app.events.on("@greeting-card.planeTurning", () => {
            if (this.isSoundOn) {
                if (this.planeIsTurning) {
                    clearTimeout(this.planeTurningTimeout);
                }
                this.planeIsTurning = true;
                if (this.planeTurning.getVolume() < 1) {
                    this.planeTurning.setVolume(2, 1);
                }
                this.planeTurningTimeout = setTimeout(() => {
                    this.planeTurning.setVolume(0.8, 1);
                    this.planeIsTurning = false;
                }, 1200);
            }
        });

        self.app.events.on("@proc-scene.planeOnTree", () => {
            this.planeOnTree.play();
        });

        self.app.events.on("@proc-scene.planeOnStone", () => {
            this.planeOnStone.play();
        });

        self.app.events.on("@greeting-card.planeOnGround", () => {
            this.planeOnGround.play();
        });

        self.app.events.on("@greeting-card.planeOnDraft", () => {
            this.planeOnDraft.play();
        });

        self.app.events.on("@greeting-card.stopGame", () => {
            this.applauseStopGame.play();
            this.displayDistance.play();
        });

        self.app.events.on("", () => {
            this.displayDistance.play();
        });
    }

}
