
<template>
    <div id="canvas-container">
    </div>
</template>

<script>

import GreetingCard from "./greeting-card";
import self from "../index";


export default {
    name: "greeting-card",
    components: {
    },
    data() {
        return {
        };
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    methods: {
    },
};

</script>

<style>

</style>
