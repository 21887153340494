<template>
    <div id="intro">
        <div class="start-bar" />


        <div class="introBackground">
            <div class="head-title intro-hidden">
                Lancez-vous
            </div>

            <div class="intro-img-wrapper">
                <div class="intro-img-container intro-hidden" />
            </div><br>
        </div>
        <div class="intro-text intro-hidden">
            Dirigez-vous avec les flèches de votre clavier,
            évitez les obstacles et prenez les courants ascendants
            pour gagner de la hauteur et aller plus loin.
        </div>
        <div class="arrows intro-hidden">
            <div id="leftArrow">
                &#60;
            </div>
            <div id="rightArrow">
                &#62;
            </div>
        </div>
        <button
            id="startButton"
            class="btn-full intro-hidden"
            @click="startGame"
        >
            Commencer
        </button>
    </div>
</template>

<script>
import anime from "animejs/lib/anime.es.js";
import self from "../../index";

export default {
    name: "intro",
    mounted() {

        self.app.events.on("@proc-scene.loader-hidden", () => {
            if (!this.animationStarted) {
                this.startAnimations();
                this.animationStarted = true;
            }
        });
    },
    methods: {
        startGame() {
            // document.body.requestFullscreen({ requireOrientation: "landscape" });
            const intro = document.getElementById("intro");
            intro.style.opacity = "0";
            setTimeout(() => {
                self.app.events.emit("startButtonPressed");
            }, 400);
        },
        startAnimations() {
            setTimeout(() => {
                const timeline = anime.timeline({
                    easing: "easeInOutCubic",
                });
                timeline.add({
                    targets: ".start-bar",
                    duration: 600,
                    scaleX: 20,
                    complete() {
                        anime({
                            targets: ".start-bar",
                            opacity: 0,
                            duration: 2000,
                        });
                        anime({
                            targets: ".intro-img-container",
                            easing: "easeOutExpo",
                            backgroundSize: "120%",
                            duration: 500,
                            delay: 200,
                        });
                        anime({
                            targets: ".head-title",
                            duration: 200,
                            opacity: "1",
                            top: "-10px",
                            easing: "easeOutQuad",
                            delay: 800,
                        });
                        anime({
                            targets: ".intro-text",
                            duration: 300,
                            opacity: "1",
                            top: "0",
                            delay: 1100,
                            easing: "easeOutQuad",

                        });
                        anime({
                            targets: ".arrows",
                            duration: 300,
                            opacity: "1",
                            top: "0",
                            delay: 1200,
                            easing: "easeOutQuad",

                        });
                        anime({
                            targets: "#startButton",
                            duration: 300,
                            opacity: "1",
                            top: "0",
                            delay: 1300,
                            easing: "easeOutQuad",

                        });
                    },
                });
                timeline.add({
                    targets: ".intro-img-container",
                    opacity: 1,
                    height: "100%",
                    duration: 500,
                });
            }, 400);
        },
    },
};

</script>

<!-- Basic style -->
<style lang="less">
    @import "../../../../style/index.less";

    .start-bar{
        position: relative;
        top: 50.9%;
        background-color:white;
        opacity: 0.2;
        height: 3px;
        width: 5%;
        margin:auto;
    }

    .intro-hidden{
        opacity: 1;
    }

    #intro {
        color: white;
        text-align: center;
        font-family: "Canela-Medium";
        font-size: 2.3vh;
        height: 97vh;
        top: 3vh;
        position: relative;
        transition: opacity .4s ease;
    }

    .backgroundImg {
        z-index: 0;
        width: 100%;
    }

    .intro-img-wrapper{
        max-height: 50vh;
        min-width: 30%;
        height: 40vh;
        width: 80vh;
        position: relative;
        margin: auto;
        margin-top: 9.5vh;
    }

    .intro-img-container{
        height:0%;
        width: 100%;
        overflow: hidden;
        position: absolute;
        bottom: 0px;
        text-align: center;
        background-image: url("/assets/images/backgroundImg.jpg");
        background-repeat: no-repeat;
        background-size: 100%;
        background-position: center bottom;
    }

    .head-title {
        font-family: "WildSpirit", Arial, sans-serif;
        font-size: 8em;
        position: absolute;
        left: calc(50% - 1.2em);
        z-index: 20;
        text-shadow: 5px 5px 5px darkslategrey;
        top: -20px;
        opacity: 0;
    }

    .intro-text {
        max-width: 50%;
        margin: 1rem auto;
        position: relative;
        background-color: #34005b;
        margin-bottom: 0;
        line-height: 1.6;
        font-size: 1em;
        opacity: 0;
        top: -10px;
    }

    .arrows {
        text-align: center;
        margin-top: 2vh;
        font-size: 1.1em;
        font-weight: bold;
        position:relative;
        top:-10px;
        opacity:0;
    }

    #leftArrow,
    #rightArrow {
        width: 2vw;
        height: 2vw;
        min-width: 15px;
        min-height: 15px;
        border-radius: 0.5vw;
        border: solid 1.5px rgba(255, 255, 255, 0.5);
        display: inline-flex;
        justify-content: center;
        align-items: center;
    }

    #leftArrow {
        margin-right: 2%;
    }

    #rightArrow {
        margin-left: 2%;
    }

    #startButton {
        /* other part of button definition can be found on index.less */
        font-family: "Montserrat-medium", Arial, sans-serif;
        font-size: 0.8em;
        margin-top: 3vh;
        position: relative;
        top:-10px;
        opacity: 0;
    }

    /* It is possible to hide the babylon sound icon
    but if it automatically mutes the sound,
    no sound can be played */
    /* #babylonUnmuteIconBtn {
        display: none;
    } */

    @media screen and (max-width: 900px) {
        .intro-text {
            max-width: 60%;
            font-size: 1em;
        }
        .arrows {
            display: none;
        }
    }

    @media screen and (max-width: 700px) {
        .intro-text {
            max-width: 75%;
        }
    }

</style>
