import BABYLON from "./babylon-modules";
import Obj from "./object";
import self from "../index";

export default class Decor extends Obj {

    constructor(scene, groundMesh) {
        super(scene, groundMesh, "decor");

        this.line = Math.floor(Math.random() * 4);
    }

    setup(mesh) {
        const meshes = mesh.getDescendants();
        meshes.push(mesh);
        meshes.forEach((submesh) => {
            if (submesh.id.includes(".__root__")) {
                this.mesh = submesh;
                this.mesh.parent = null;
                this.mesh.scaling = new BABYLON.Vector3(0.01, 0.01, 0.01);
            }
        });

        super.definePosition(this.line);

        self.app.events.emit("loadedMesh", this);
    }

}
