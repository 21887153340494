<template>
    <div id="sound-container">
        <div>Sound {{ sound ? "on" : "off" }}
            <div class="circle-button" @click="toggleMute">
                <svg fill="white" stroke="white" v-if="sound" width="15" height="12" viewBox="0 0 15 12">
                    <g fill="none" fill-rule="evenodd">
                        <path fill="#FFF" fill-rule="nonzero" d="M6.613 1.876L3.12 3.656H.16A.162.162 0 0 0 0 3.818v4.82c0 .087.073.16.16.16H2.88l.007.005.277.156 3.45 1.941a.162.162 0 0 0 .241-.139V2.015c0-.127-.135-.193-.241-.139z"/>
                        <g stroke="#FFF" stroke-linecap="square" stroke-width="1.53">
                            <path d="M9.693 8.437a3.427 3.427 0 0 0 0-4.847M12.192 10.936a6.961 6.961 0 0 0 0-9.845"/>
                        </g>
                    </g>
                </svg>

                <svg v-else xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14">
                    <path fill="#FFF" fill-rule="nonzero" d="M1.237 2.787l8.97 8.222v1.969c0 .155-.153.256-.294.222l-.06-.023-5.031-2.785-.405-.224-.01-.007H.44a.237.237 0 0 1-.226-.17l-.009-.06V3.018c0-.104.074-.195.173-.222l.062-.008h.796zM.676 0l3.04 2.787h1.043l2.547-1.276L9.854.234c.136-.068.304-.004.344.135l.008.064v8.303l3.207 2.94-.676.737L0 .737.676 0z"/>
                </svg>

            </div>
        </div>
    </div>
</template>

<script>

import self from "../../index";

export default {
    name: "sound",
    data() {
        return {
            sound: true,
        };
    },
    mounted() {
        self.app.events.on("@audio-engine.audioEngineReady", () => {
            self.app.modules.audioEngine.isSoundOn = this.sound;
        });
    },
    methods: {
        toggleMute: function() {
            this.sound = !this.sound;
            self.app.events.emit("setSound", this.sound);
        }
    },
};

</script>
<!-- Basic style -->
<style lang="less">

    #sound-container {
        user-select: none;
        position: absolute;
        right: 0;
    }

</style>
