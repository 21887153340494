import TWEEN from "@tweenjs/tween.js";
import ExplosionManager from "./explosion-manager";
import BABYLON from "./babylon-modules";
import self from "../index";

// const { engine, materialManager } = self.app.modules;

export default class GreetingCard {

    constructor() {
        this.engine = self.app.modules.obsidianBabylonEngine;
        this.engine.waitForLoading().then(() => {
            this.scene = this.engine.scene;
            this.materialManager = self.app.modules.obsidianMaterialManager;
            this.mainLoop = this.engine.mainLoop;

            this.cardSize = 1;
            this.cardHeight = this.cardSize * 0.3;

            this.rotationAnimation = null;
            this._altitude = 0; // Useful for visual only
            this.maxAltitude = 9;

            this.startingAlt = this.cardHeight;
            this.loseAltitudeTime = 4;
            this.rotationPointSpeed = 0.004;
            this.gainAltDraft = 5;
            this.rotationPlaneSpeed = 0;

            this.procScene = self.app.modules.procScene.controller;

            this.rattling = 0;
            this.shake = 0;
            const RATL = 777;
            const CAMERAFOV = 1;

            this.scene.registerBeforeRender(() => {
                if (this.rattling > 0) {
                    this.rattling -= 1;
                    this.shake += 0.02;
                    if (this.rattling % 6 === 0) {
                        this.procScene.camera.fov = CAMERAFOV + this.shake / RATL;
                        this.procScene.camera.rotation.y = this.shake / RATL;
                    } else if (this.rattling % 6 === 3) {
                        this.procScene.camera.fov = CAMERAFOV - this.shake / RATL;
                        this.procScene.camera.rotation.y = -this.shake / RATL;
                    }
                } else {
                    this.procScene.camera.fov = CAMERAFOV;
                    this.procScene.camera.rotation.y = 0;
                }
            });

            self.app.events.on("@equilibrage.updateProperty", (uiData) => {
                if (this.loseAltAnimation) {
                    this.loseAltAnimation.stop();
                    this.loseAltitude();
                } else if (this.gainAltAnimation) {
                    this.gainAltAnimation.stop();
                    this.loseAltitude();
                }
                this.loseAltitudeTime = parseFloat(uiData.timeLoseAlt);
                this.rotationPointSpeed = parseFloat(uiData.speedRotationPlane) * 0.001;
                this.gainAltDraft = parseFloat(uiData.gainAltDraft);
            });

            self.app.events.on("@greeting-card.loadedPlane", () => {
                this.initialRotation = this.card.rotation.clone();
                this.procScene.card = this.card;

                const children = this.card.getDescendants();
                for (let i = 0; i < children.length; i++) {
                    if (children[i].isEnabled()) {
                        this.procScene.shadowGenerator.getShadowMap().renderList.push(children[i]);
                    }
                }

                Object.defineProperty(this.card.position, "y", {
                    get: () => this.altitude + this.cardHeight * 0.5,
                    set: (value) => {
                        this.altitude = value;
                    },
                });

                this.card.position.y = this.startingAlt;

                const cameraOffset = new BABYLON.Vector3(this.cardSize * 3, this.cardSize, 0);
                this.procScene.camera.setPosition(this.card.position.clone().add(cameraOffset));
                this.procScene.camera.setTarget(this.cardPoint.position); // not cloned: camera follows card
                this.initialCamAlpha = this.procScene.camera.alpha;
                this.initialCamBeta = this.procScene.camera.beta;
                this.initialCamRadius = this.procScene.camera.radius;

                this.procScene.shadowGenerator.getShadowMap().renderList.push(this.card);

                this.explosionManager = new ExplosionManager(this.card);
            });

            self.app.events.on("@proc-scene.groundSphereLoaded", () => {
                this.rotationPoint.addChild(this.procScene.groundSphere);
            });

            self.app.events.on("@vuejs.restart", () => {
                this.resetGame();
            });

            self.app.events.on("@proc-scene.loadAltitude", () => {
                this.loadAltitude();
            });

            self.app.events.on("@proc-scene.stopLoadAltitude", () => {
                this.stopLoadAltitude();
            });

            if (this.procScene.setupped) {
                this.setup();
            } else {
                self.app.events.on("@proc-scene.setupped", () => {
                    this.setup();
                });
            }
        });
    }

    resetGame() {
        if (this.scene.actionManager) {
            this.scene.actionManager.dispose();
        }
        this.scene.actionManager = new BABYLON.ActionManager(this.scene);

        this.card.position.copyFromFloats(0, this.startingAlt, 0);

        this.altitude = this.startingAlt;
        this.loseAltitudeTime = 4;
        this.cardHeight = this.cardSize * 0.2;
        this.card.rotation.copyFromFloats(0, Math.PI / 2, 0);

        this.update = (loopInfo) => {
            if (loopInfo.idle) {
                return;
            }

            TWEEN.update(loopInfo.deltaTime);
            this.updateScene(loopInfo);
        };

        this.mainLoop.addCallback(this.update);

        // this.startingAltitudeAction();
    }

    set altitude(value) {
        this._altitude = value - this.cardHeight * 0.5;
        this.procScene.altitude.value = this._altitude;
    }

    get altitude() {
        return this._altitude;
    }

    setup() {
        this.materialManager.init(this.scene, BABYLON);

        this.procScene.createMesh("paper_plane.gltf").then((mesh) => {
            const meshes = mesh.getDescendants();
            meshes.push(mesh);
            meshes.forEach((submesh) => {
                if (submesh.id === ".__root__") {
                    this.card = submesh;
                } else if (submesh.id.includes("collision")) {
                    submesh.isVisible = false;
                }
                submesh.receiveShadows = true;
            });
            this.card.parent = null;
            this.card.scaling = new BABYLON.Vector3(0.01, 0.01, 0.01);

            if (this.card.rotationQuaternion) {
                const rotEuler = this.card.rotationQuaternion.toEulerAngles();
                this.card.rotationQuaternion = null;
                this.card.rotation = rotEuler;
            }
            this.card.computeWorldMatrix(true);
            this.card.rotation.copyFromFloats(0, Math.PI / 2, 0);

            this.card.receiveShadows = true;

            this.cardPoint.position = this.card.position;
            this.cardPoint.setParent(this.card);

            self.app.events.emit("loadedPlane");

            this.resetGame();

        });

        this.cardPoint = BABYLON.MeshBuilder.CreateBox("cardPoint", {}, this.scene);
        const cardPointMaterial = new BABYLON.StandardMaterial("cardPointMaterial", this.scene);
        cardPointMaterial.alpha = 0;
        this.cardPoint.material = cardPointMaterial;

        // Rotation point for the sphere (on axis X)
        this.rotationPoint = BABYLON.MeshBuilder.CreateBox(
            "rotationPoint", {
                size: 1,
            },
            this.scene
        );
        this.rotationPoint.position = new BABYLON.Vector3(0, -50, 0);

        this.procScene.cardSize = this.cardSize;
    }

    // startingAltitudeAction() {
    //     this.loadAltitudeAction = new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnKeyDownTrigger, (evt) => {
    //         if (evt.sourceEvent.key === " ") {
    //             if (!this.startingAltitudeAnimation) {
    //                 self.app.events.emit("loadAltitude");
    //                 this.loadAltitude();
    //             }
    //         }
    //     });

    //     this.startGameAction = new BABYLON.ExecuteCodeAction(BABYLON.ActionManager.OnKeyUpTrigger, (evt) => {
    //         if (evt.sourceEvent.key === " ") {
    //             self.app.events.emit("stopLoadAltitude");
    //             this.stopLoadAltitude();
    //         }
    //     });

    //     this.scene.actionManager.registerAction(this.loadAltitudeAction);

    //     this.scene.actionManager.registerAction(this.startGameAction);
    // }

    loadAltitude() {
        const pointsAltitude = [-0.019 * (this.maxAltitude - 2),
            0.102 * (this.maxAltitude - 2),
            0.829 * (this.maxAltitude - 2),
            0.987 * (this.maxAltitude - 2)];

        const timeAltAnim = 4300;
        this.startingAltitudeAnimation = new TWEEN.Tween(this.card.position)
            .to({ y: pointsAltitude }, timeAltAnim)
            .interpolation(TWEEN.Interpolation.Bezier)
            .easing(TWEEN.Easing.Cubic.Out)
            .onComplete(() => {
                self.app.events.emit("stopLoadAltitude");
                this.stopLoadAltitude();
            })
            .start();

        this.startingRotationAnimation = new TWEEN.Tween(this.card.rotation)
            .to({ x: 0.4 }, 1500)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                // this.startingRotationAnimation = new TWEEN.Tween(this.card.rotation)
                //     .to({ x: -Math.PI / 12 }, 1500)
                //     .easing(TWEEN.Easing.Quadratic.InOut)
                //     .start();
            })
            .start();

        this.startingRotationCamera = new TWEEN.Tween(this.procScene.camera)
            .to({ beta: this.procScene.camera.beta + 0.5 }, 1500)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .onComplete(() => {
                // this.startingRotationCamera = new TWEEN.Tween(this.procScene.camera)
                //     .to({ beta: this.procScene.camera.beta - 0.5 }, 1500)
                //     .easing(TWEEN.Easing.Quadratic.InOut)
                //     .start();
            })
            .start();

        this.delayShakeCamera = setTimeout(() => {
            this.shake = 0;
            this.rattling = 240;
        }, timeAltAnim - 1800);
    }

    stopLoadAltitude() {
        this.scene.actionManager.unregisterAction(this.loadAltitudeAction);
        this.scene.actionManager.unregisterAction(this.startGameAction);
        if (this.startingAltitudeAnimation) {
            this.startingAltitudeAnimation.stop();

        }
        if (this.startingRotationAnimation) {
            this.startingRotationAnimation.stop();
        }
        if (this.startingRotationCamera) {
            this.startingRotationCamera.stop();
        }
        this.test = new TWEEN.Tween(this.card.position)
            .to({ y: this.card.position.y + 2 }, 2600)
            .easing(TWEEN.Easing.Back.Out)
            .onStart(() => {
                this.rattling = 0;
                clearTimeout(this.delayShakeCamera);
            })
            .onComplete(() => {
                this.loseAltitude();
            })
            .start();

        this.startingRotationAnimation = new TWEEN.Tween(this.card.rotation)
            .to({ x: -Math.PI / 12 }, 2200)
            .easing(TWEEN.Easing.Cubic.Out)
            .start();

        this.startingRotationCamera = new TWEEN.Tween(this.procScene.camera)
            .to({ beta: this.initialCamBeta }, 1500)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .start();

        const distance = 2;
        new TWEEN.Tween(this.procScene.camera)
            .to({ radius: this.procScene.camera.radius + distance }, 800)
            .easing(TWEEN.Easing.Sinusoidal.Out)
            .onComplete(() => {
                new TWEEN.Tween(this.procScene.camera)
                    .to({ radius: this.procScene.camera.radius - distance }, 1800)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .start();
            })
            .start();

        this.startGame();
    }

    startGame() {
        self.app.events.emit("startGame", this.rotationPoint);
        this.procScene.cardSize = this.cardSize;
        this.moveCard();
    }

    moveCard() {
        self.app.events.on("@proc-scene.leftDown", () => {
            this.leftPressed = true;
            this.rightPressed = false;
            this.startRotAnimation();
            self.app.events.emit("planeTurning", this.leftPressed, this.rightPressed);
        });

        self.app.events.on("@proc-scene.leftUp", () => {
            this.leftPressed = false;
            this.backRotAnimation();
        });

        self.app.events.on("@proc-scene.rightDown", () => {
            this.rightPressed = true;
            this.leftPressed = false;
            this.startRotAnimation();
            self.app.events.emit("planeTurning", this.leftPressed, this.rightPressed);
        });

        self.app.events.on("@proc-scene.rightUp", () => {
            this.rightPressed = false;
            this.backRotAnimation();
        });
    }

    startRotAnimation() {
        if (this.rotationAnimation) {
            this.rotationAnimation.stop();
            this.rotationCameraAnimation.stop();
        }
        if (this.backRotationAnimation) {
            this.backRotationAnimation.stop();
            this.backRotationCameraAnimation.stop();
        }
        if (this.loseAltRotAnimation) {
            this.loseAltRotAnimation.stop();
        }

        const rotationX = this.initialRotation.x - 0.2;
        let rotationZ = this.initialRotation.z;
        let rotationY = this.initialRotation.y;
        let cameraRotation = 0;
        if (this.leftPressed) {
            rotationZ -= 0.6;
            rotationY -= 0.1;
            cameraRotation = 0.1;
        } else if (this.rightPressed) {
            rotationZ += 0.6;
            rotationY += 0.1;
            cameraRotation = -0.1;
        }

        this.rotationAnimation = new TWEEN.Tween(this.card.rotation)
            .to({ y: rotationY, z: rotationZ }, 800)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();

        if (this.gainAltRotAnimation && !this.gainAltRotAnimation.isPlaying()) {
            this.gainAltRotAnimation.stop();

            this.rotationXAnimation = new TWEEN.Tween(this.card.rotation)
                .to({ x: rotationX }, 800)
                .easing(TWEEN.Easing.Quadratic.Out)
                .start();
        }

        this.rotationCameraAnimation = new TWEEN.Tween(this.procScene.camera)
            .to({ alpha: this.initialCamAlpha + cameraRotation, radius: this.initialCamRadius + 0.8 }, 1000)
            .easing(TWEEN.Easing.Quadratic.Out)
            .start();

        // this.rotationCameraAnimation = new TWEEN.Tween(this.procScene.camera)
        //     .to({ alpha: cameraRotation }, time * 2)
        //     .easing(TWEEN.Easing.Quartic.InOut)
        //     .start();
    }

    backRotAnimation() {
        if (this.backRotationAnimation) {
            this.rotationAnimation.stop();
            this.rotationCameraAnimation.stop();
            this.backRotationAnimation.stop();
            this.backRotationCameraAnimation.stop();
        }

        if (this.gainAltAnimation && !this.gainAltAnimation.isPlaying()) {
            if (this.card.position.y > 1.5) {
                this.loseAltRotAnimation = new TWEEN.Tween(this.card.rotation)
                    .to({ x: -Math.PI / 12 }, 1200)
                    .easing(TWEEN.Easing.Sinusoidal.Out)
                    .start();
            }
        }

        this.backRotationAnimation = new TWEEN.Tween(this.card.rotation)
            .to({ y: this.initialRotation.y, z: 0 }, 1500)
            .easing(TWEEN.Easing.Sinusoidal.InOut)
            .start();


        this.backRotationCameraAnimation = new TWEEN.Tween(this.procScene.camera)
            .to({ alpha: this.initialCamAlpha, radius: this.initialCamRadius }, 2400)
            .easing(TWEEN.Easing.Quadratic.InOut)
            .start();

        // this.backRotationCameraAnimation = new TWEEN.Tween(this.procScene.camera)
        //     .to({ alpha: this.initialCamAlpha }, 1500)
        //     .easing(TWEEN.Easing.Quartic.InOut)
        //     .start();
    }

    updateScene(loopInfo) {
        if (loopInfo.idle || isNaN(loopInfo.timeSinceLastCall)) {
            return;
        }

        let factor = loopInfo.timeSinceLastCall / 16;

        if (this.hasCollided) {
            this.leftPressed = false;
            this.rightPressed = false;
        } else {
            if (this.leftPressed) {
                if (this.rotationPoint.rotation.x < 0.32) {
                    // this.rotationPoint.rotation.x += this.rotationPointSpeed * this.rotationPlaneSpeed;
                    this.rotationPoint.rotation.x += this.rotationPointSpeed * factor;
                }
            }
            if (this.rightPressed) {
                if (this.rotationPoint.rotation.x > -0.32) {
                    // this.rotationPoint.rotation.x -= this.rotationPointSpeed * this.rotationPlaneSpeed;
                    this.rotationPoint.rotation.x -= this.rotationPointSpeed * factor;
                }
            }
            if (this.rotationPlaneSpeed < 1) {
                this.rotationPlaneSpeed += 0.015;
            }
            if (this.loseAltitudeTime > 1.5) {
                this.loseAltitudeTime -= 0.0002;
            }
            this.updateCamera();
            this.checkCollisions();
        }
    }

    updateCamera() {
        if (this.card.position.y < 1.5) {
            if (this.card.rotation.x < 0) {
                this.card.rotation.x += 0.0003;
            }
        //     const cameraPositionY = 0.7;
            // this.procScene.camera.setPosition(
            //     new BABYLON.Vector3(this.procScene.camera.position.x, cameraPositionY, this.procScene.camera.position.z)
            // );
        }
    }

    loseAltitude() {
        this.groundPosition = this.procScene.groundSphere.absolutePosition.y
            + this.procScene.groundRadius
            + this.cardHeight * 0.5;

        this.loseAltAnimation = new TWEEN.Tween(this.card.position)
            .to({ y: this.groundPosition }, this.card.position.y * this.loseAltitudeTime * 1000)
            .easing(TWEEN.Easing.Sinusoidal.InOut)
            .onComplete(() => {
                self.app.events.emit("planeOnGround");
                this.explosionManager.explode();
                this.rotationPlaneSpeed = 0;
                self.app.events.emit("explode");
                this.hasCollided = true;
                setTimeout(() => {
                    this.stopGame();
                }, 3200);
            })
            .start();

        this.loseAltRotAnimation = new TWEEN.Tween(this.card.rotation)
            .to({ x: -Math.PI / 12 }, 1200)
            .easing(TWEEN.Easing.Sinusoidal.Out)
            .start();
    }

    gainAltitude() {
        if (this.gainAltAnimation) {
            this.gainAltAnimation.stop();
            this.gainAltCameraAnim.stop();
            if (this.gainAltRotAnimation) {
                this.gainAltRotAnimation.stop();
            }
        }
        if (this.loseAltRotAnimation) {
            this.loseAltRotAnimation.stop();
        }
        if (this.rotationAnimation) {
            this.rotationAnimation.stop();
        }

        let altToGain = this.maxAltitude - this.card.position.y;
        if (altToGain > this.gainAltDraft) {
            altToGain = this.gainAltDraft;
        }

        if (altToGain > 0.8) {
            this.gainAltAnimation = new TWEEN.Tween(this.card.position)
                .to({ y: this.card.position.y + altToGain }, 800)
                .easing(TWEEN.Easing.Quadratic.InOut)
                .onComplete(() => {
                    this.loseAltitude();
                })
                .start();

            const rot = altToGain * 0.01;
            this.gainAltRotAnimation = new TWEEN.Tween(this.card.rotation)
                .to({ x: rot }, 500)
                .easing(TWEEN.Easing.Quadratic.Out)
                .onComplete(() => {
                    this.gainAltRotAnimation = new TWEEN.Tween(this.card.rotation)
                        .to({ x: -Math.PI / 12 }, 300)
                        .easing(TWEEN.Easing.Quadratic.Out)
                        .start();
                })
                .start();
        } else {
            const position = this.card.position.y + altToGain;
            this.gainAltAnimation = new TWEEN.Tween(this.card.position)
                .to({ y: position + 0.8 }, 300)
                .easing(TWEEN.Easing.Quadratic.In)
                .onComplete(() => {
                    this.gainAltAnimation = new TWEEN.Tween(this.card.position)
                        .to({ y: position }, 500)
                        .easing(TWEEN.Easing.Quadratic.Out)
                        .onComplete(() => {
                            this.loseAltitude();
                        })
                        .start();
                })
                .start();
        }

        const rotCam = altToGain * 0.02;

        this.gainAltCameraAnim = new TWEEN.Tween(this.procScene.camera)
            .to({ beta: this.initialCamBeta + rotCam }, 800)
            .easing(TWEEN.Easing.Quadratic.Out)
            .onComplete(() => {
                this.gainAltCameraAnim = new TWEEN.Tween(this.procScene.camera)
                    .to({ beta: this.initialCamBeta }, 1000)
                    .easing(TWEEN.Easing.Quadratic.InOut)
                    .start();
            })
            .start();
    }

    checkCollisions() {
        // Drafts
        if (this.hasCollided) {
            return;
        }

        if (this.procScene.testCollidersDrafts()) {
            this.loseAltAnimation.stop();
            this.gainAltitude();
            self.app.events.emit("planeOnDraft");
        }

        // Obstacles
        if (this.procScene.testColliders()) {
            this.explosionManager.explode();
            this.rotationPlaneSpeed = 0;
            this.loseAltAnimation.stop();
            self.app.events.emit("explode");
            this.hasCollided = true;
            setTimeout(() => {
                this.stopGame();
            }, 3200);
        }
    }

    stopGame() {
        if (this.startingAltitudeAnimation) {
            this.startingAltitudeAnimation.stop();
            this.startingAltitudeAnimation = null;
        }

        if (this.loseAltAnimation) {
            this.loseAltAnimation.stop();
            this.loseAltAnimation = null;
        }

        if (this.loseAltRotAnimation) {
            this.loseAltRotAnimation.stop();
            this.loseAltRotAnimation = null;
        }

        if (this.gainAltAnimation) {
            this.gainAltAnimation.stop();
            this.gainAltAnimation = null;
        }

        if (this.gainAltRotAnimation) {
            this.gainAltRotAnimation.stop();
            this.gainAltRotAnimation = null;
        }

        if (this.backRotationAnimation) {
            this.backRotationAnimation.stop();
            this.backRotationAnimation = null;
        }

        if (this.rotationAnimation) {
            this.rotationAnimation.stop();
            this.rotationAnimation = null;
        }

        this.rotationPoint.rotation.copyFromFloats(0, 0, 0);
        this.mainLoop.removeCallback(this.update);
        this.hasCollided = false;

        self.app.events.emit("stopGame");
    }

}
