import BABYLON from "./babylon-modules";
import Obj from "./object";
import self from "../index";
import TWEEN from "@tweenjs/tween.js";

export default class Draft extends Obj {

    constructor(scene, groundMesh, line) {
        super(scene, groundMesh, "draft");

        this.line = line;
        this.draftCollision = null;
    }

    setup(mesh) {

        const meshes = mesh.getDescendants();
        meshes.push(mesh);
        meshes.forEach((submesh) => {
            if (submesh.id === ".__root__") {
                this.mesh = submesh;
                this.mesh.parent = null;
                this.mesh.scaling = new BABYLON.Vector3(0.01, 0.01, 0.01);
            } else if (submesh.id.includes("collision")) {
                this.draftCollision = submesh;
                submesh.isVisible = false;
            }

            if (submesh.id === ".troudair.troudair") {
                if (submesh.rotationQuaternion) {
                    const rotEuler = submesh.rotationQuaternion.toEulerAngles();
                    submesh.rotationQuaternion = null;
                    submesh.rotation = rotEuler;
                }

                let upwardDraft = new TWEEN.Tween(submesh.rotation).repeat(Infinity)
                    .to({ z: submesh.rotation.z + 2 * Math.PI }, 1000).start();
            }
            if (submesh.material) {
                submesh.material.backFaceCulling = false;
            }
        });
        super.definePosition(this.line);

        self.app.events.emit("loadedMesh", this);
    }

}
