<template>
    <div class="procScene">
        <div class="distance">
            <p id="score">
                {{ computedDistance }}
            </p>
            <p id="mesure">
                {{ computedUnit }}
            </p>
        </div>
        <!-- <div class="altitude">
            <p>Altitude: {{ altitude.value.toPrecision(2) }}</p>
        </div> -->
        <div id="spaceInfo">
            <div
                id="oval"
                @mousedown="loadAltitude"
                @pointerdown="loadAltitude"
                @mouseup="stopLoadAltitude"
                @pointerup="stopLoadAltitude" />
            <div id="spaceText" />
        </div>
        <div id="loading-altitude" />
        <div id="arrowInfo">
            <button
                id="leftArrowGame"
                disabled
                @mousedown="leftDown"
                @pointerdown="leftDown"
                @mouseup="leftUp"
                @pointerup="leftUp">
                <p>&#60;</p>
            </button>
            <button
                id="rightArrowGame"
                disabled
                @mousedown="rightDown"
                @mouseup="rightUp"
                @pointerdown="rightDown"
                @pointerup="rightUp">
                <p>&#62;</p>
            </button>
        </div>

        <div id="transition-container"><div id="transition"></div></div>
    </div>
</template>

<script>
import lottie from "lottie-web";
import self from "../index";

export default {
    name: "proc-scene",
    components: {},
    data() {
        return {};
    },
    computed: {
        computedDistance() {
            return parseInt(this.distance.value, 10);
        },
        computedUnit() {
            return (this.computedDistance > 1) ? "mètres" : "mètre";
        },
    },
    watch: {},
    mounted() {
        const spaceDiv = document.getElementById("spaceInfo");
        this.loadAlt = false;
        const leftArrow = document.getElementById("leftArrowGame");
        const rightArrow = document.getElementById("rightArrowGame");
        const loadingAltitudeEl = document.getElementById("loading-altitude");

        const loadingAltitudeText = document.getElementById("spaceText");
        this.loadingAltitudeTextAnimation = lottie.loadAnimation({
            container: loadingAltitudeText, // the dom element that will contain the animation
            renderer: "svg",
            loop: true,
            autoplay: true,
            path: "assets/images/loadAltitudeText.json", // the path to the animation json
        });

        self.app.events.on("@asset-manager.assetsLoaded", () => {
            spaceDiv.style.opacity = "1";
        });

        self.app.events.on("@vuejs.startButtonPressed", () => {
            document.addEventListener("keydown", this.spaceDownEvent);
            this.loadingAltitudeAnimation = lottie.loadAnimation({
                container: loadingAltitudeEl, // the dom element that will contain the animation
                renderer: "svg",
                loop: false,
                autoplay: false,
                path: "assets/images/loaderAltitude.json", // the path to the animation json
            });
        });

        self.app.events.on("@greeting-card.startGame", () => {
            this.loadAlt = true;
            // TODO reset this.gameLaunched doit être mis à false au reset
            this.gameLaunched = false;
            const arrowDiv = document.getElementById("arrowInfo");
            const leftArrowGame = document.getElementById("leftArrowGame");
            const rightArrowGame = document.getElementById("rightArrowGame");
            arrowDiv.style.opacity = "1";
            leftArrowGame.disabled = false;
            rightArrowGame.disabled = false;
            spaceDiv.style.opacity = "0";
            loadingAltitudeEl.style.opacity = "0";
            setTimeout(() => {
                spaceDiv.style.display = "none";
                loadingAltitudeEl.style.display = "none";
                this.loaderAltitudeOff();
            }, 1000);

            document.addEventListener("keydown", this.arrowDownEvent);
            document.addEventListener("keyup", this.arrowUpEvent);
        });

        self.app.events.on("@greeting-card.planeTurning", (leftPressed, rightPressed) => {
            if (leftPressed) {
                leftArrow.style.backgroundColor = "white";
            }
            if (rightPressed) {
                rightArrow.style.backgroundColor = "white";
            }
        });

        self.app.events.on("@greeting-card.planeStopTurningLeft", () => {
            leftArrow.style.backgroundColor = "rgba(255, 255, 255, 0.79)";
        });

        self.app.events.on("@greeting-card.planeStopTurningRight", () => {
            rightArrow.style.backgroundColor = "rgba(255, 255, 255, 0.79)";
        });

        self.app.events.on("@greeting-card.explode", () => {
            const transition = document.getElementById("transition");
            const transitionContainer = document.getElementById("transition-container");
            transition.style.top = "0";
            transition.style.left = "0";
            transition.style.width = "100%";
            transition.style.height = "100%";
            transitionContainer.style.height = "100%";
            transitionContainer.style.width = "100%";
        });

        self.app.events.on("@vuejs.restart", () => {
            loadingAltitudeEl.style.display = "block";
            loadingAltitudeEl.style.opacity = "1";

            this.loadAlt = false;
            spaceDiv.style.display = "block";
            spaceDiv.style.opacity = "1";

            const arrowDiv = document.getElementById("arrowInfo");
            const leftArrowGame = document.getElementById("leftArrowGame");
            const rightArrowGame = document.getElementById("rightArrowGame");
            const transition = document.getElementById("transition");
            const transitionContainer = document.getElementById("transition-container");
            arrowDiv.style.opacity = "0";
            leftArrowGame.disabled = true;
            rightArrowGame.disabled = true;

            transition.style.top = "50%";
            transition.style.left = "50%";
            transition.style.width = "0%";
            transition.style.height = "0%";
            transitionContainer.style.height = "0%";
            transitionContainer.style.width = "0%";
        });
    },
    methods: {
        spaceDownEvent(pressedKey) {
            if (pressedKey.key === " ") {
                this.loadAltitude();
                document.addEventListener("keyup", this.spaceUpEvent);
            }
        },
        spaceUpEvent(pressedKey) {
            if (pressedKey.key === " ") {
                this.stopLoadAltitude();
                document.removeEventListener("keyup", this.spaceDownEvent);
                document.removeEventListener("keyup", this.spaceUpEvent);
            }
        },
        arrowDownEvent(pressedKey) {
            if (pressedKey.key === "ArrowLeft") {
                this.leftDown();
            }
            if (pressedKey.key === "ArrowRight") {
                this.rightDown();
            }
        },
        arrowUpEvent(pressedKey) {
            if (pressedKey.key === "ArrowLeft") {
                this.leftUp();
            }
            if (pressedKey.key === "ArrowRight") {
                this.rightUp();
            }
        },
        loadAltitude() {
            if (!this.loadAlt && !this.gameLaunched) {
                this.gameLaunched = true;
                const oval = document.getElementById("oval");
                oval.style.backgroundColor = "white";
                this.loaderAltitudeOn();
                self.app.events.emit("loadAltitude");
            }
        },
        loaderAltitudeOn() {
            this.loadingAltitudeAnimation.play();
        },
        loaderAltitudeOff() {
            this.loadingAltitudeAnimation.stop();
        },
        stopLoadAltitude() {
            if (!this.loadAlt) {
                const oval = document.getElementById("oval");
                oval.style.backgroundColor = "rgba(255, 255, 255, 0.1)";
                self.app.events.emit("stopLoadAltitude");
                this.loaderAltitudeOff();
                this.loadAlt = true;
            }
        },
        leftDown() {
            self.app.events.emit("leftDown");
        },
        leftUp() {
            const leftArrow = document.getElementById("leftArrowGame");
            leftArrow.style.backgroundColor = "rgba(255, 255, 255, 0.79)";
            self.app.events.emit("leftUp");
        },
        rightDown() {
            self.app.events.emit("rightDown");
        },
        rightUp() {
            const rightArrow = document.getElementById("rightArrowGame");
            rightArrow.style.backgroundColor = "rgba(255, 255, 255, 0.79)";
            self.app.events.emit("rightUp");
        },
    },
};

</script>

<style lang="less">
    @font-face {
        font-family: "Canela-bold";
        src: url("/assets/fonts/Canela/Canela-Bold.otf");
    }

    .procScene {
        display: flex;
        z-index: 1;
        justify-content: center;
    }

    .info {
        position: relative;
    }

    .altitude {
        position: absolute;
        left: 20px;
        width: 140px;
        height: 20px;
        padding: 2px;
        margin: 10px;
        font-size: 16px;
        color: #d6dbdd;
    }

    .distance {
        width: 220px;
        border-radius: 6px;
        box-shadow: 0 5px 7px -3px rgba(0, 0, 0, 0.5);
        background-color: #34005b;
        padding-bottom: 1em;
        text-align: center;
        margin: auto;
    }

    .distance>p {
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        line-height: normal;
        letter-spacing: normal;
        color: #ffffff;
        margin: 0;
    }

    #score {
        font-family: "Montserrat-bold";
        font-size: 4em;
    }

    #mesure {
        font-family: "Canela-bold";
        font-size: 1.5em;
    }

    #oval,
    #leftArrowGame,
    #rightArrowGame {
        cursor: pointer;
        z-index: 20;
    }

    #spaceInfo,
    #arrowInfo {
        position: absolute;
        transition: opacity 1s linear;
        opacity: 0;
    }

    #spaceInfo {
        bottom: 18%;
        width: 11vh;
        height: 11vh;
        z-index: 20;
        outline: none;

        &:focus {
            outline: none;
        }

        &:active {
            outline: none;
        }
    }

    #oval {
        width: 100%;
        height: 100%;
        border: solid 2px #34005b;
        background-color: rgba(255, 255, 255, 0.1);
        border-radius: 50%;
        position: absolute;
        box-sizing: border-box;

        &:hover {
            background-color: rgba(255, 255, 255, 0.4);
        }

        &:active {
            background-color: rgba(255, 255, 255, 1);
        }
    }

    #spaceText {
        position: absolute;
        color: white;
        z-index: 19;
        width: 43vh;
        bottom: -181%;
        height: 51vh;
        left: -145.4%;
        transition: opacity 1s linear;

        svg{
            width: 100%;
        }
        &.hidden {
            pointer-events: none;
        }
    }

    #loading-altitude {
        position: absolute;
        color: white;
        z-index: 19;
        width: 46vh;
        bottom: 73.55%;
        transition: opacity 1s linear;

        svg{
            width: 100%;
        }
        &.hidden {
            pointer-events: none;
        }
    }

    #arrowInfo {
        bottom: 4%;
        width: 150px;
        z-index: 10;
        text-align: center;
        color :rgba(0, 0, 0, 0.5);

        outline: none;

        &:focus {
            outline: none;
        }

        &:active {
            outline: none;
        }
    }

    @media screen and (max-width: 900px) {
        #score {
            font-size: 3em;
        }

        #mesure {
            font-size: 1.3em;
        }

        #arrowInfo {
            width: 90%;
            bottom: 19%;
        }
    }

    @media screen and (max-width: 700px) and (orientation: portrait) {
        .distance {
            padding-top: 1.5em;
        }
        #arrowInfo {
            bottom: 12%;
        }
    }

    #leftArrowGame,
    #rightArrowGame {
        width: 57px;
        height: 57px;
        border-radius: 8px;
        border: solid 1px #ffffff;
        background-color: rgba(255, 255, 255, 0.79);
        display: inline-block;

        &:hover {
            background-color: rgba(255, 255, 255, 0.5);
        }

        &:active {
            background-color: white;
        }

        &:disabled {
            cursor: auto;
        }
    }

    #leftArrowGame {
        margin-right: 2%;
        float: left;
    }

    #rightArrowGame {
        margin-left: 2%;
        float: right;
    }

    #arrowInfo p {
        font-family: monospace;
        margin: 0;
        font-size: 28px;
    }

    #transition-container {
        overflow: hidden;
        position: absolute;
        z-index: 0;
        top: 0;
        left: 0;
        height: 0;
        width: 0;
    }
    #transition {
        width: 0;
        height: 0;
        background-color: #34005b;
        z-index: 20;
        position: absolute;
        left: 50%;
        top: 50%;
        transition: height 1s ease 2s, width 1s ease 2s, top 1s ease 2s, left 1s ease 2s;
    }

</style>
