import { MeshBuilder } from "@babylonjs/core/Meshes/meshBuilder";
import { Quaternion, Vector3, Space } from "@babylonjs/core/Maths/math";
import { StandardMaterial } from "@babylonjs/core/Materials/standardMaterial";
import { ShadowGenerator } from "@babylonjs/core/Lights/Shadows/shadowGenerator";
import { DirectionalLight } from "@babylonjs/core/Lights/directionalLight";
import { SceneLoader } from "@babylonjs/core/Loading/sceneLoader";

const BABYLON = {
    MeshBuilder,
    Quaternion,
    Vector3,
    StandardMaterial,
    ShadowGenerator,
    DirectionalLight,
    Space,
    SceneLoader,
};
export default BABYLON;
