import self from "../index";

export default class CustomLoadingScreen {

    constructor() {
        // init the loader
        this.loadingUIText = "";
    }

    displayLoadingUI() {
        if (document.getElementById("loading-screen")) {
            document.getElementById("loading-screen").style.display = "block";
        }
    }

    hideLoadingUI() {
        if (document.getElementById("loading-screen")) {
            document.getElementById("loading-screen").style.opacity = 0;
            setTimeout(()=>{
            document.getElementById("loading-screen").style.display = "none";

            },400)
            self.app.events.emit("loader-hidden");
        }
    }

}
