export default [
    "caillou.gltf",
    "pititeplante.gltf",
    "decor.gltf",
    "paper_plane.gltf",
    "tree.000.gltf",
    "tree.001.gltf",
    "tree.002.gltf",
    "troudair1.gltf",
    "rock2.gltf",
    "rock3.gltf",
    "test.gltf",
];
