<template>
    <!-- canvas template -->
    <div id="canvas-container">
        <canvas id="main-canvas" />
    </div>
</template>


<script>
// We start the BabylonJs engine once the canvas html is builted (=> mounted function)
import self from "../../index";

export default {
    name: "main-canvas",
    mounted() {
        self.app.modules.obsidianBabylonEngine.start();
        const canvas = document.getElementById("main-canvas");


        self.app.events.on("@equilibrage.updateField", () => canvas.focus());
        self.app.events.on("@vuejs.startButtonPressed", () => {
            const canvasContainer = document.getElementById("canvas-container");
            canvasContainer.style.zIndex = "10";
            canvas.style.opacity = "1";
            setInterval(() => {
                canvas.focus();
            }, 600);
        });

        self.app.events.on("@vuejs.restart", () => {
            const canvasContainer = document.getElementById("canvas-container");
            canvasContainer.style.zIndex = "10";
        });
    },
};
</script>

<!-- Basic style -->
<style lang="less">
    #main-canvas {
        touch-action: none;
        height: calc(100% - 106px);
        width: 95%;
        margin-top: 50px;
        opacity: 0;
        transition: opacity .4s ease;
    }
    #canvas-container {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        z-index: -10;
    }
</style>
