import TWEEN from "@tweenjs/tween.js";
import BABYLON from "./babylon-modules";

class ExplosionManager {

    constructor(mesh) {
        this.mesh = mesh;
        this.particleSystem = null;
        this.emissionDuration = 300;
        this.initParticleSystem();
        this.exploded = false;

        self.app.events.on("@vuejs.restart", () => {
            this.mesh.getChildMeshes().forEach((c) => {
                c.visibility = 1;
            });
            this.mesh.visibility = 1;
            this.exploded = false;
            this.particleSystem.dispose();
            this.initParticleSystem();
        });

    }

    explode() {
        if (!this.exploded) {
            // particles
            this.particleSystem.start();
            setTimeout(() => {
                this.particleSystem.stop();
            }, this.emissionDuration);
            this.exploded = true;

            // scaling animation
            new TWEEN.Tween(this.mesh.scaling)
                .to({ x: 0.02, y: 0.02, z: 0.02 }, 300)
                .easing(TWEEN.Easing.Cubic.InOut)
                .onComplete(() => {
                    this.mesh.scaling = new BABYLON.Vector3(0.01, 0.01, 0.01);
                })
                .start();

            // visibility animation
            const visibilityObject = { alpha: 1 };
            new TWEEN.Tween(visibilityObject)
                .to({ alpha: 0 }, 200)
                .onUpdate(() => {
                    this.mesh.getChildMeshes().forEach((c) => {
                        c.visibility = visibilityObject.alpha;
                    });
                    this.mesh.visibility = visibilityObject.alpha;
                })
                .start();
        }
    }

    initParticleSystem() {
        const particleSystem = new BABYLON.ParticleSystem("particles", 5000, this.mesh.getScene());
        particleSystem.emitRate = 20000;
        particleSystem.particleEmitterType = new BABYLON.SphereParticleEmitter(20);
        particleSystem.particleTexture = new BABYLON.Texture("assets/textures/flare.png", this.mesh.getScene());
        particleSystem.maxLifeTime = 4;
        particleSystem.color1 = BABYLON.Color3.FromHexString("#FF5CA9");
        particleSystem.color2 = BABYLON.Color3.FromHexString("#06475B");
        particleSystem.minSize = 0.03;
        particleSystem.minEmitPower = 400;
        particleSystem.maxEmitPower = 4000;
        particleSystem.maxSize = 0.3;
        particleSystem.emitter = this.mesh;
        this.particleSystem = particleSystem;
    }

}
export default ExplosionManager;
