// import BABYLON from "./babylon-modules";
import * as BABYLON from "@babylonjs/core/Legacy/legacy";
import "@babylonjs/loaders/glTF";
import self from "../index";
import Obstacle from "./obstacle";
import Obj from "./object";
import Draft from "./draft";
import Decor from "./decor";
import CustomLoadingScreen from "./custom-loading-screen";
// import Cylinder from "./cylinder";

export default class ProcScene {

    constructor() {
        this.engine = self.app.modules.obsidianBabylonEngine;
        this.altitude = { value: 0 };
        this.distance = { value: 0 };
        this.cardSize = 1;

        this.ASSETS_PATH = "../../../assets/";
        this.allModels = new Map();
        this._cachedModels = new Map();

        this.initEngine();
        this._meshesToCloneQueue = [];
        // this.planeCollider = new Cylinder();
    }

    /**
     * Engine must be ready so we can overlay BabylonEngine loading screen
     */
    initLoadingScreen() {
        const loadingScreen = new CustomLoadingScreen();
        // replace the default loading screen
        this.engine.engine.loadingScreen = loadingScreen;
        // show the loading screen
        this.engine.engine.displayLoadingUI();

        self.app.events.on("@asset-manager.assetsLoaded", () => {
            // hide the loading screen when you want to
            this.engine.engine.hideLoadingUI();
        });
    }

    initEngine() {
        return this.engine.waitForLoading().then(() => {

            this.initLoadingScreen();

            this.scene = this.engine.scene;
            this.assetsManager = new BABYLON.AssetsManager(this.scene);
            this.materialManager = self.app.modules.obsidianMaterialManager;
            this.mainLoop = this.engine.mainLoop;
            this.camera = this.engine.camera;
            this.lights = this.engine.lights;

            this.obstacles = []; // same as decors?
            this.drafts = [];
            this.decors = [];
            this.collisionContainers = [];
            this.collisionChildren = [];

            this.initialSpeedRotation = 0.006;
            this.speedRotation = this.initialSpeedRotation;
            this.frequencyObstacles = 700; // milisec
            this.nbObstaclesMin = 1; // for each line
            this.nbObstaclesMax = 3;
            this.frequencyDrafts = 0.2; // probability of a draft appearing

            this.rotationPoint = null;
            this.obstaclesWithoutDraft = 0;

            this.update = (loopInfo) => {
                this.updateScene(loopInfo);
            };

            self.app.events.on("@greeting-card.startGame", (rotationPoint) => {
                this.startTime = Date.now();
                this.gameStarted = true;
                this.createObjects();
                this.createDecors();

                setTimeout(() => {
                    this.nbObstaclesMin = 2;
                }, 60000);

                this.rotationPoint = rotationPoint;

                this.mainLoop.addCallback(this.update);
            });

            self.app.events.on("@vuejs.restart", () => {
                this.gameStarted = true;
                this.distance.value = 0;
                this.altitude.value = 0;
                this.nbObstaclesMin = 1;
                this.speedRotation = this.initialSpeedRotation;

                setTimeout(() => {
                    this.nbObstaclesMin = 2;
                }, 60000);

                for (let i = this.obstacles.length - 1; i >= 0; i--) {
                    this.cleanCollider(this.obstacles[i]);
                    // this.obstacles[i].mesh.dispose();
                    this.softDispose(this.obstacles[i].mesh);
                    this.obstacles.splice(i, 1);
                }

                for (let i = this.drafts.length - 1; i >= 0; i--) {
                    // this.drafts[i].mesh.dispose();
                    this.softDispose(this.drafts[i].mesh);
                    this.drafts.splice(i, 1);
                }

                for (let i = this.decors.length - 1; i >= 0; i--) {
                    // this.decors[i].mesh.dispose();
                    this.softDispose(this.decors[i].mesh);
                    this.decors.splice(i, 1);
                }
            });

            self.app.events.on("@greeting-card.stopGame", () => {
                this.mainLoop.removeCallback(this.update);
                this.gameStarted = false;
                this._meshesToCloneQueue = [];
                clearTimeout(this.obstaclesCreation);
                clearTimeout(this.decorsCreation);
            });

            self.app.events.on("@greeting-card.explode", () => {
                this.speedRotation = 0;
            });

            self.app.events.on("@equilibrage.updateProperty", (uiData) => {
                this.speedRotation = parseFloat(uiData.speedRotation * 0.001);
                this.frequencyObstacles = parseFloat(uiData.frequencyObstacles) * 1000;
                this.nbObstaclesMin = parseInt(uiData.nbObstaclesMin, 10);
                this.nbObstaclesMax = parseInt(uiData.nbObstaclesMax, 10);
                this.frequencyDrafts = parseFloat(uiData.frequencyDrafts);
            });

            self.app.events.on("@equilibrage.changeCameraControls", () => {
                this.scene.activeCamera.attachControl(this.engine.engine.getRenderingCanvas());
            });

            self.app.events.on("@proc-scene.loadedMesh", (obj) => {
                if (obj.collisionContainer) {
                    this.obstacles.push(obj);
                    this.collisionContainers.push(obj.collisionContainer);
                }
                if (obj.collisionChildren) {
                    this.collisionChildren.push(obj.collisionChildren);
                }
                if (obj.draftCollision) {
                    this.drafts.push(obj);
                }

                const children = obj.mesh.getDescendants();
                for (let i = 0; i < children.length; i++) {
                    if (children[i].isEnabled()) {
                        this.shadowGenerator.getShadowMap().renderList.push(children[i]);
                    }
                }
            });

            this.assetsManager.onTasksDoneObservable.add(() => {
                this.setup();
            });
        });
    }

    setup() {
        this.materialManager.init(this.scene, BABYLON);

        this.scene.clearColor = new BABYLON.Color3(0.95, 0.95, 0.85);
        this.scene.collisionsEnabled = true;

        /* Ground sphere */
        this.groundRadius = this.cardSize * 50;

        this.createMesh("decor.gltf").then((mesh) => {
            const meshes = mesh.getDescendants();
            meshes.push(mesh);
            meshes.forEach((submesh) => {
                if (submesh.id === ".__root__") {
                    this.groundSphere = submesh;
                    this.groundSphere.parent = null;
                    this.groundSphere.scaling = new BABYLON.Vector3(0.01, 0.01, 0.01);
                    this.groundSphere.position.y = -this.groundRadius;

                    if (this.groundSphere.rotationQuaternion) {
                        const rotEuler = this.groundSphere.rotationQuaternion.toEulerAngles();
                        this.groundSphere.rotationQuaternion = null;
                        this.groundSphere.rotation = rotEuler;
                    }
                    this.groundSphere.computeWorldMatrix(true);
                    this.groundSphere.rotation.copyFromFloats(0, Math.PI / 2, 0);

                    this.groundSphere.receiveShadows = true;
                }
                submesh.receiveShadows = true;
            });
            self.app.events.emit("groundSphereLoaded");
        });

        this.preloadMeshCache();

        /* Sun */
        this.lights.sunPoint = new BABYLON.PointLight("pointLight", new BABYLON.Vector3(-80, -22, -50), this.scene);
        this.tick = 0;

        /* Sky */
        this.sky = BABYLON.MeshBuilder.CreatePlane(
            "sky", {
                width: 512,
                height: 275,
            },
            this.scene
        );
        this.sky.position = new BABYLON.Vector3(-100, -40, 0);
        this.sky.rotation.y = -Math.PI / 2;
        const skyMaterial = new BABYLON.StandardMaterial("skyMaterial", this.scene);
        skyMaterial.diffuseTexture = new BABYLON.Texture("/assets/images/sky2.png", this.scene);
        this.sky.material = skyMaterial;

        /* Lights */
        this.lights.hemispheric.specular = new BABYLON.Color3(0.2, 0.2, 0.2); // No glare from light

        this.lights.directional = new BABYLON.DirectionalLight("directionalLight", new BABYLON.Vector3(1, -2, 0.5), this.scene);
        this.lights.directional.position.copyFromFloats(-38, 12, 2.6).scaleInPlace(0.5);
        this.lights.directional.intensity = 0.8;
        this.lights.directional.specular = new BABYLON.Color3(0.2, 0.2, 0.2);
        this.lights.directional.shadowMinZ = 0.1;
        this.lights.directional.shadowMaxZ = 500;
        this.lights.directional.shadowFrustumSize = 40;

        /* Shadow Generator */
        this.shadowGenerator = new BABYLON.ShadowGenerator(2048, this.lights.directional);
        this.shadowGenerator.useBlurExponentialShadowMap = true;
        this.shadowGenerator.blurKernel = 32;
        this.shadowGenerator.setDarkness(0.5);

        /* Meshes with shadows */
        this.shadowGenerator.forceBackFacesOnly = true;
        this.shadowGenerator.getShadowMap().renderList.push(this.groundSphere);

        /* Camera */
        this.camera.wheelPrecision = 10;
        this.camera.fov = 1;
        this.camera.keysLeft = [];
        this.camera.keysRight = [];
        this.camera.keysUp = [];
        this.camera.keysDown = [];

        this.scene.activeCamera.detachControl(this.engine.engine.getRenderingCanvas());

        self.app.events.emit("setupped");
        this.setupped = true;
    }

    preloadMeshCache() {
        const meshesList = [
            "tree.000.gltf",
            "tree.001.gltf",
            "tree.002.gltf",
            "rock2.gltf",
            "rock3.gltf",
            "troudair1.gltf",
            "caillou.gltf",
            "pititeplante.gltf"];

        for (let i = 0; i < meshesList.length; i++) {
            for (let k = 0; k < 10; k++) {
                this.createMesh(meshesList[i]).then(mesh => this.softDispose(mesh));
            }
        }
    }

    createObjects() {
        if (!this.mainLoop.idle) {
            let nbObstacles = 0;
            let createDraft = false;

            nbObstacles = Math.floor(Math.random() * (this.nbObstaclesMax + 1 - this.nbObstaclesMin)) + this.nbObstaclesMin;
            createDraft = Math.random() <= this.frequencyDrafts;
            const lines = [0, 1, 2, 3, 4];

            if (this.obstaclesWithoutDraft < 6 || !createDraft) {
                createDraft = false;
                this.obstaclesWithoutDraft += 1;
            }
            if (this.obstaclesWithoutDraft > 16) {
                createDraft = true;
            }

            for (let i = 0; i < nbObstacles; i += 1) {
                const line = Math.floor(Math.random() * lines.length);
                if (createDraft) {
                    this.obstaclesWithoutDraft = 0;
                    const draft = new Draft(this.scene, this.groundSphere, lines[line]);
                    this.createMesh("troudair1.gltf").then((mesh) => {
                        draft.setup(mesh);
                        this.shadowGenerator.getShadowMap().renderList.push(draft.mesh);
                    });
                    createDraft = false;
                    // this.drafts.push(draft);
                } else {
                    const obstacleList = ["tree.000.gltf", "tree.001.gltf", "tree.002.gltf", "rock2.gltf", "rock3.gltf"];
                    // const obstacleList = ["tree.000.gltf", "tree.001.gltf", "tree.002.gltf"];
                    const randomObstacle = Math.floor(Math.random() * obstacleList.length);
                    const obstacle = new Obstacle(obstacleList[randomObstacle], this.scene, this.groundSphere, lines[line]);
                    this.createMesh(obstacleList[randomObstacle]).then((mesh) => {
                        obstacle.setup(mesh);
                    });
                }
                lines.splice(line, 1);
            }
        }

        this.obstaclesCreation = setTimeout(this.createObjects.bind(this), this.frequencyObstacles);
    }

    createDecors() {
        const nbDecors = Math.ceil(Math.random() * 2);
        for (let i = 0; i < nbDecors; i += 1) {
            const typeDecor = Math.floor(Math.random() * 2);
            const listDecor = ["caillou.gltf", "pititeplante.gltf"];
            const decor = new Decor(this.scene, this.groundSphere);
            this.createMesh(listDecor[typeDecor]).then((mesh) => {
                decor.setup(mesh);
            });
            this.decors.push(decor);
        }

        this.decorsCreation = setTimeout(this.createDecors.bind(this), 500);
    }

    updateScene(loopInfo) {
        if (loopInfo.idle || isNaN(loopInfo.timeSinceLastCall)) {
            return;
        }

        const factor = loopInfo.timeSinceLastCall / 16;

        this.groundSphere.rotation.x += this.speedRotation * factor;
        this.obstacles.forEach((obstacle) => {
            if (obstacle.mesh && !obstacle.isVisible()) {
                this.cleanCollider(obstacle);
                this.softDispose(obstacle.mesh);
                this.obstacles.splice(this.obstacles.indexOf(obstacle), 1);
            }
        });
        this.drafts.forEach((draft) => {
            if (draft.mesh && !draft.isVisible()) {
                this.softDispose(draft.mesh);
                this.drafts.splice(this.drafts.indexOf(draft), 1);
            }
        });
        this.decors.forEach((decor) => {
            if (decor.mesh && !decor.isVisible()) {
                this.softDispose(decor.mesh);
                this.decors.splice(this.decors.indexOf(decor), 1);
            }
        });
        this.distance.value += this.speedRotation * 40 * factor;
        if (this.speedRotation < 0.004) {
            this.speedRotation += 0.00000007;
        }
        if (this.frequencyObstacles > 600) {
            this.frequencyObstacles -= 0.008;
        }

        this.processMeshesToCloneQueue();

    }

    softClone(mesh) {
        const id = mesh.url;
        const list = this._cachedModels.get(id);
        let newMesh;

        if (!list || !list.length) {
            newMesh = mesh.clone();
        } else {
            newMesh = list.pop();
        }

        newMesh.setEnabled(true);
        self.app.events.emit("mesh-cloned", newMesh);
        newMesh.url = mesh.url;
        return newMesh;
    }

    softDispose(mesh) {
        if (!mesh) {
            return;
        }

        const id = mesh.url;
        let list = this._cachedModels.get(id);

        if (!list) {
            list = [];
            this._cachedModels.set(id, list);
        }

        if (list.length > 10) {
            // Cache size is 3
            mesh.dispose();
            return;
        }

        mesh.setEnabled(false);
        mesh._usedForDelayedLoading = false;

        list.push(mesh);
    }


    testColliders() {
        if (!this.card) {
            return;
        }

        this.card.refreshBoundingInfo();

        for (let i = 0; i < this.collisionContainers.length; i++) {
            this.collisionContainers[i].refreshBoundingInfo();
            if (this.card.intersectsMesh(this.collisionContainers[i], true)) {
                // Boundingbox are in collision, unitary test
                if (this.fineTestCollider(this.collisionChildren[i], this.card)) {
                    if (this.obstacles[i].obstacleType.includes("tree")) {
                        self.app.events.emit("planeOnTree");
                    } else if (this.obstacles[i].obstacleType.includes("rock")) {
                        self.app.events.emit("planeOnStone");
                    }
                    return true;
                }
            }
        }

        return false;
    }

    fineTestCollider(colliders, mesh) {
        for (let i = 0; i < colliders.length; i++) {
            colliders[i].refreshBoundingInfo();
            if (mesh.intersectsMesh(colliders[i], true)) {
                // Boundingbox are in collision, unitary test
                return true;
            }
        }

        return false;
    }

    testCollidersDrafts() {
        if (!this.card) {
            return;
        }

        const bb = this.card.getBoundingInfo().boundingBox;

        for (let i = 0; i < this.drafts.length; i++) {
            if (this.drafts[i].hasAlreadyCollided) {
                continue;
            }
            this.drafts[i].draftCollision.refreshBoundingInfo();
            if (BABYLON.BoundingBox.Intersects(bb, this.drafts[i].draftCollision.getBoundingInfo().boundingBox)) {
                // Boundingbox are in collision, unitary test
                this.drafts[i].hasAlreadyCollided = true;
                return true;
            }
        }
        return false;
    }

    cleanCollider(obj) {
        this.safeRemoveFromList(this.collisionContainers, obj.collisionContainer);
        this.safeRemoveFromList(this.collisionChildren, obj.collisionChildren);
        this.safeRemoveFromList(this.shadowGenerator.getShadowMap().renderList, obj.mesh);
    }

    safeRemoveFromList(list, obj) {
        let idx = list.indexOf(obj);
        if (idx !== -1) {
            list.splice(idx, 1);
        }
    }

    loadMeshes() {
        this.assetsManager.load();
    }

    preloadMeshInstantly(url, onSuccess) {
        let promise = this.addToPreloadList(url, onSuccess);
        this.assetsManager._isLoading = false;
        this.assetsManager.load();

        return promise;
    }

    addToPreloadList(url, onSuccess) {
        const path = `${this.ASSETS_PATH}models/`;
        const name = `load ${path}${url}`;
        const meshTask = this.assetsManager.addMeshTask(name, "", path, url);
        const { allModels } = this;

        return new Promise((resolve, reject) => {
            meshTask.onSuccess = (task) => {
                task.loadedMeshes[0].setEnabled(false);
                allModels.set(url, task.loadedMeshes[0]);
                if (onSuccess) {
                    onSuccess(task);
                }

                resolve(task);
            };

            meshTask.onError = (task, message, exception) => {
                console.log(message, exception);
                const mesh = BABYLON.BoxBuilder.CreateBox("placeholder", { size: 0.5 }, this.scene);
                mesh.setEnabled(false);

                allModels.set(url, mesh);

                reject(message);
            };
        });
    }

    processMeshesToCloneQueue() {
        if (!this._meshesToCloneQueue.length) {
            return;
        }

        const meshToClone = this._meshesToCloneQueue.shift();
        this.softClone(meshToClone);
    }

    createMesh(modelName) {
        if (!this.allModels.get(modelName)) {
            console.warn(`${modelName} was not preloaded, resulting in a loss of flawless user experience.`);
            console.warn(`Asynchronously loading ${modelName} now...`);

            return this.preloadMeshInstantly(modelName).then(() => this.createMesh(modelName));
        }

        const mesh = this.allModels.get(modelName);
        mesh.url = modelName;

        // Avoid costly cloning if a mesh cache is already available
        // Queue mesh creation
        this._meshesToCloneQueue.push(mesh);

        return new Promise((resolve) => {
            const cb = (newMesh) => {
                if (!newMesh._usedForDelayedLoading && newMesh.url === mesh.url) {
                    self.app.events.removeListener("mesh-cloned", cb);
                    newMesh._usedForDelayedLoading = true;
                    resolve(newMesh);
                }
            };

            self.app.events.on("mesh-cloned", cb);

            // if (!this.gameStarted) {
                this.processMeshesToCloneQueue();
            // }
        });
    }

}
