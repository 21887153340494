import "@babel/polyfill";

import httpRequest from "@obsidianjs/http-request";
import obsidian from "@obsidianjs/obsidian";
import MainLoop from "@obsidianjs/main-loop";
import ObsidianBabylonEngine from "obsidian-babylon-engine";
import ObsidianMaterialManager from "obsidian-material-manager";
import Vuejs from "./modules/vuejs";
// import Torus from "./modules/torus";
import GreetingCard from "./modules/greeting-card";
import ProcScene from "./modules/proc-scene";
import AudioEngine from "./modules/audio-engine";
import AssetManager from "./modules/asset-manager";
import Equilibrage from "./modules/equilibrage";

import "./style/index.less";

const app = obsidian("starter-app");
app.use(httpRequest);
app.use(Vuejs);
app.use(AssetManager);
app.use(MainLoop, {
    config: {
        debug: false,
    },
});
app.use(ObsidianBabylonEngine, {
    config: {
        antialias: true,
        exampleCamera: true,
        exampleLight: true,
        canvasId: "main-canvas",
        autostart: false,
    },
});
app.use(ObsidianMaterialManager, {
    config: {
        texturePath: "/assets/textures/",
    },
});
// app.use(Torus);
app.use(AudioEngine);
app.use(GreetingCard);
app.use(ProcScene);
app.use(Equilibrage);

app.start();

window.app = app;
