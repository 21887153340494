
<template>
    <div id="equilibrage">
        <br><br><br>
        <p>
            <label for="timeLoseAlt">Temps perte altitude</label>
            <input
                id="timeLoseAlt"
                v-model="uiData.timeLoseAlt"
                v-on:change="fieldChanged"
                type="number"
                name="timeLoseAlt"
            >
            <br>
            <label>(en secondes, pour une mesure sur Y)</label>
        </p>
        <p>
            <label for="speedRotation">Vitesse rotation</label>
            <input
                id="speedRotation"
                v-model="uiData.speedRotation"
                v-on:change="fieldChanged"
                type="number"
                name="speedRotation"
            >
        </p>
        <p>
            <label for="speedRotation">Vitesse rotation latérale</label>
            <input
                id="speedRotationPlane"
                v-model="uiData.speedRotationPlane"
                v-on:change="fieldChanged"
                type="number"
                name="speedRotationPlane"
            >
        </p>
        <p>
            <label for="gainAltDraft">Hauteur prise avec un trou d'air</label>
            <input
                id="gainAltDraft"
                v-model="uiData.gainAltDraft"
                v-on:change="fieldChanged"
                type="number"
                name="gainAltDraft"
            >
        </p>
        <p>
            <label for="frequencyObstacles">Fréquence apparition obstacles</label><br>
            <label>Toutes les</label>
            <input
                id="frequencyObstacles"
                v-model="uiData.frequencyObstacles"
                v-on:change="fieldChanged"
                type="number"
                name="frequencyObstacles"
            >
            <label>seconde(s)</label>
        </p>
        <p>
            <label for="NbObstacles">Nombre d'obstacles, de </label>
            <input
                id="nbObstaclesMin"
                v-model="uiData.nbObstaclesMin"
                v-on:change="fieldChanged"
                type="number"
                name="nbObstaclesMin"
            >
            <label>à</label>
            <input
                id="nbObstaclesMax"
                v-model="uiData.nbObstaclesMax"
                v-on:change="fieldChanged"
                type="number"
                name="nbObstaclesMax"
            >
            <label>par ligne</label>
        </p>
        <p>
            <label for="frequencyDrafts">Probabilité apparition trous d'air</label><br>
            <input
                id="frequencyDrafts"
                v-model="uiData.frequencyDrafts"
                v-on:change="fieldChanged"
                type="number"
                name="frequencyDrafts"
            >
            <label>(de 0 à 1)</label>
        </p>
        <p id="errorMessage">
            {{ uiData.errorMessage }}
        </p>
        <button
            id="camera"
            @click="cameraControls">
            Camera
        </button>
    </div>
</template>

<script>

import self from "../../index";


export default {
    name: "equilibrage",
    components: {
    },
    computed: {
    },
    watch: {
    },
    mounted() {
    },
    methods: {
        fieldChanged() {
            self.app.events.emit("updateField");
        },
        cameraControls() {
            self.app.events.emit("changeCameraControls");
        },
    },
};

</script>

<style lang="less">

    #equilibrage {
        width: 30%;
        height: 55%;
        z-index: 150;
        float: right;
        background-color: rgba(36, 52, 65, 0.8);
        position: absolute;
        top: 0;
        right: 0;
    }

    #equilibrage > p {
        width: 100%;
        float: right;
    }

    label {
        color: white;
    }

    input {
        width: 60px;
    }

    #errorMessage {
        color: rgb(167, 0, 0);
    }
</style>
