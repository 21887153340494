export default {
    name: "equilibrage",
    requires: ["obsidian-babylon-engine", "obsidian-material-manager", "proc-scene", "greeting-card"],
    exposed: ["uiData"],
    load() {
        const Equilibrage = require("./src/controller").default;
        const EquilibrageVue = require("./src/templates/equilibrage.vue").default;
        const c = new Equilibrage();
        this.bind(EquilibrageVue, c, this.exposed);
        return {
            controller: c,
            view: EquilibrageVue,
        };
    },
    unload() {},

    bind(vue, controller, exposed) {
        vue.methods = vue.methods || {};
        const data = vue.data ? vue.data() : {};

        for (let i = 0; i < exposed.length; i++) {
            if (controller[exposed[i]] instanceof Function) {
                vue.methods[exposed[i]] = (...args) => controller[exposed[i]](...args);
            } else {
                data[exposed[i]] = controller[exposed[i]];
            }
        }

        vue.data = () => data;
    },
};
